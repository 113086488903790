.dashbooardContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 20px;
  margin-bottom: 30px;
}

.dashbooardMenu {
  width: 278px;
  height: 980px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000033; */
  opacity: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
}

.newDashbooardMenu{
  width: 278px;
  height: 600px;
  background: #ffffff;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-top: 1px solid #a7959d;
  border-bottom: 1px solid #a7959d;
  border-right: 1px solid #a7959d;
  display: flex;
  flex-direction: column;
  justify-content:center;

}

.newMenu{
  font-family:"Helvetica Neue"!important;
  font-size: 20px;
  cursor: pointer;
  color:#532c3e;
  /* margin-top: 25px; */
  /* margin-bottom: 25px; */
  padding-left: 20px;
  margin-top:0px;
}

.newMenuSelected{
  font-family:"Helvetica Neue"!important;
  color:#e10c69;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  /* margin-top: 25px; */
  /* margin-bottom: 25px; */
  padding-left: 20px;
  margin-top: 0px;
}

.menu {
  height: 28px;
  font-size: 24px;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 20px;
}

.menuSelected {
  height: 28px;
  padding-left: 20px;
  font-size: 24px;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
}

.menu:hover, .newMenu:hover {
  font-weight: bold;
}

.menuImg {
  font-family:"Helvetica Neue";
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 60px;
  object-fit: cover;
}

.newMenuImg{
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  border-radius: 50%;
  object-fit: cover;
}

.imgSub {
  align-self: center;
  font-size: 1.25em;
  margin: 0px;
  margin-bottom: 80px;
  margin-top: 12px;
  padding-left: 40px;
  padding-right: 40px;
}

.newImgSub{
  align-self: center;
  font-size: 1.25em;
  margin: 0px;
  margin-bottom: 30px;
  margin-top: 12px;
  padding-left: 40px;
  padding-right: 40px;
}

.imgStatus {
  margin: 0;
  font-size: 12px;
  text-align: center;
  color: #ce6868;
}

.menuDetail {
  flex-direction: column;
  font-display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 1430px;
}

.dashTitle {
  /* background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 160%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029; */
  /* border-radius: 25px; */
  font-size: 25px;
  flex: 1 1;
  margin-left: -13px;
  /* margin-top: 180px; */
  color: #e30064;
  padding: 10px;
  /* padding-left: 76px; */
  margin-bottom:87px;
}

.dashBlocked {
  padding: 30px;
  border: 3px solid #ce6868;
  border-radius: 25px;
  margin-bottom: 40px;
}

.dashForm {
    /* background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 200%) 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 25px;
    padding-top: 30px;
    border: 3px solid #e20062;
    padding-left: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.dashFormTitle {
  color:#491f32;
  font-size: 35px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 60px;
  color: #532c3e;
}

h2,h4{
  color:#491f32;
}

.newDashFormTitle{
  font-size: 45px!important;
  margin: 0px;
  padding: 0px;
  margin-bottom: 60px;
  font-family:"Helvetica Neue"!important;
  color:#e10c69;
  font-size: 20px;
  font-weight: bold;
}

.dashRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
}

.dashCol {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-right: 20px;
  text-align: center;
}

.dashColInput {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 50px;
}

.dashLabel {
  margin: 0px;
  font-size: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
  align-self: center;
  padding-bottom: 0px;
  color: #532c3e;
}

.dashInput {
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  font-size: 20px;
  width: 95% !important;
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;
}

.dashInput-new{
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e30064;
    border-radius: 10px;
    padding: 16px;
    font-size: 20px;
    outline-style: none !important;
    width: 94% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    /* margin-top: 28px; */
    color: #491f32;
    font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;
}

.dashInput2-new{
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e30064;
    border-radius: 10px;
    padding: 16px;
    font-size: 20px;
    outline-style: none !important;
    width: 94% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #491f32;
    font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;
}

.dashInputPack {
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border: 1px solid #471d30;
  border-radius: 5px;
  font-size: 20px;
  width: 25% !important;
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
  display: flex;
  flex-direction: column;
}

.packInput {
  color:#471d30;
  border: none;
  font-size: 16px;
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
  margin-top: auto;
  border-top: solid #471d30 1px;
  padding: 10px;
}

.dashAdd {
  padding: 20px;
  padding-left: 25px!important;
  padding-right: 25px!important;
  width: 17px;
  height: 17px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e30064;
  border-radius: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.giftImg {
  width: 87px;
  height: 57px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  object-fit: cover
}
.filePreview {
  width: 60px;
  height: 60px;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  border:1px solid #491f32;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
}

.picturePreview {
  height: 200px;
  border-radius: 24px;
  margin-left: 15px;
  margin-right: 15px;
}

.videoPreview {
  width: 300px;
  border-radius: 24px;
  margin-left: 15px;
  margin-right: 15px;
  outline: none;
}

.dashAddNo {
  padding: 20px;
  padding-left: 35px;
  padding-right: 35px;
  width: 17px;
  height: 17px;
  margin-right: 20px;
  cursor: pointer;
}
.newDashAddNo {
  padding: 20px;
  padding-left: 35px;
  /* padding-right: 35px; */
  width: 20px;
  height: 20px;
  /* margin-right: 20px; */
  cursor: pointer;
}

.dashFile {
  margin: 0px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  font-size: 18px;
  color: #959595;
  align-self: center;
  margin-right: 5px;
}

.row {
  flex-direction: row;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  overflow-x: auto;
}

/* Hide scrollbar for Chrome,
Safari and Opera .row::-webkit-scrollbar {
  display: none;
} */

.rowSub {
  flex-direction: row;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #b5b5b5;
  margin-right: 80px;
}
.newRowSub {
  flex-direction: row;
  display: flex;
  margin-bottom: -30px;
  align-items: center;
  justify-content: space-around;
  /* border-bottom: 2px solid #b5b5b5; */
  margin-right: 80px;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.rowCamp {
  flex-direction: row;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-around;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  margin-right: 80px;
}

.newRowCamp{
  flex-direction: row;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-around;
  margin-right: 80px;
}

.col {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.btn {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 28px;
  line-height: 28px;
  margin-top: 60px;
  width: 260px;
  margin-left: 20px;
  cursor: pointer;
}

.delBtn {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 28px;
  line-height: 28px;
  margin-top: 5px;
  width: 100px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.btnGeneral:hover {
  background-color: #507cab;
  color: white;
}

.smbtn {
  border: 3px solid #507cab;
  border-radius: 60px;
  text-align: center;
  font-size: 15px;
  color: #000000;
  opacity: 1;
  text-align: center;
  line-height: 28px;
  margin-top: 10px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
}

.btnRight {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 1.25em;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 28px;
  line-height: 28px;
  width: 260px;
  margin-left: auto;
  cursor: pointer;
}

.dashBtns {
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.dashSub {
  font-size: 24px;
  width: 25%;
  text-align: center;
  color: #532c3e;
}
p.tableCell {
  color: #532c3e;
}
.newDashSub {
  font-size: 28px;
  width: 25%;
  text-align: center;
  color:#5c3748;
  font-family:"Helvetica Neue";
  font-weight: bold;
}

.dashFact {
  font-size: 24px;
  text-align: center;
}

.dashCamptxt {
  font-size: 22px;
  width: 25%;
  text-align: center;
}
.newDashCamptxt{
  font-size: 24px;
  width: 25%;
  text-align: center;
  font-family:"Helvetica Neue";
}

.btnLeftDash {
  border: 3px solid #507cab;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;

  font-size: 1.25em;
  padding: 5px;
  margin-right: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.btnRightDash {
  border: 3px solid #507cab;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  font-size: 1.25em;
  padding: 5px;
  margin-left: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.btnRoundDash {
  border: 3px solid #507cab;
  border-radius: 60px;
  font-size: 1.25em;
  padding: 5px;
  margin-left: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}
.btnCenterDash {
  border: 3px solid #507cab;
  font-size: 1.25em;
  padding: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.rowBtn {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  margin-right: 80px;
  margin-bottom: 30px;
}
.newRowBtn{
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  margin-right: 80px;
  margin-bottom: 30px;
}

.smllRow {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  margin-right: 60px;
}

.smallTxt {
  color: #6c6c6c;
  font-size: 16px;
  margin: 0px;
  align-self: center;
}

.dashCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 25px;
  flex-direction: row;
  display: flex;
  width: 270px;
  margin-right: 60px;
}

.dashAvatar {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  width: 103px;
  height: 137px;
  object-fit: cover;
  margin-right: 30px;
}

.dashBlueTxt {
  color: #507cab;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 5px;
}

.dashPrice {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  font-size: 40px;
  margin: 0px;
  padding: 12px;
  padding-left: 35px;
  padding-right: 35px;
}

.radioContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  flex: 1;
  flex-direction: row;
  margin-right: 50px;
}

.bankAcountTxt {
  margin: 0px;
  font-size: 22px;
  font-weight: 600;
}

.priceRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background: #e30064 0% 0% no-repeat padding-box;
  border-radius: 60px;
  padding: 10px;
  width: 667px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.line {
  width: 0px;
  height: 49px;
  border: 1px solid #ffffff;
  margin-left: 30px;
  margin-right: 30px;
}

.priceTxt {
  font-size: 35px;
  color: white;
  margin: 0px;
}

.fact-first {
  width: 350px;
  flex-direction: column;
}

.fact-sec {
  width: 280px;
  flex-direction: column;
  border-left: 2px solid #b5b5b5;
  border-right: 2px solid #B5B5B5;
  align-self: center;
}

.fact-third {
  flex-direction: column;
  flex: 4;
}

.factRow {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
}

.noMar {
  margin: 0px;
  align-self: center;
}

.fact-row {
  padding: 20px;
  margin: 0px;
  border-bottom: 2px solid #B5B5B5;
}

.fact-txt {
  border-bottom: 2px solid #B5B5B5;
  padding: 20px;
}

.DonSearchBar {
  background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff66;
  border-radius: 25px;
  flex-direction: row;
  flex: 1;
  margin-right: 20px;
  padding: 5px;
  padding-left: 15px;
  margin-top: 5px;
}

.DonInput {
  font-size: 2.3em;
  color: black;
  border-color: transparent !important;
  background-color: transparent;
  outline-style: none;
  margin: 0px;
  margin-left: 20px;
  width: 90%;
}

.DonIcon {
  width: 24px;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}

.DonTable {
  background: transparent linear-gradient(0deg, #ffffff 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff66;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: 1200px;
  height: 300px;
  overflow: scroll;
}

.don-txt {
  color: #517cab;
  font-size: 1.25em;
  font-weight: 600;
  border-bottom: 2px solid #B5B5B5;
  padding: 10px;
}

.don-subtxt {
  font-size: 1.25em;
  border-bottom: 2px solid #B5B5B5;
  padding: 10px;
}

.don-first {
  width: 250px !important;
  flex-direction: column;
}

.don-sec {
  width: 250px !important;
  flex-direction: column;
  border-left: 2px solid #B5B5B5;
  align-self: center;
  /* min-width: 100px; */
}

.don-third {
  flex-direction: column;
  border-left: 2px solid #B5B5B5;
  width: 250px !important;
}

.selectCampain {
  width: 450px;
  height: 42px;
  border-radius: 40px;
  outline: none;
}

th {
  padding: 10px;
  color: #e30064!important;
  text-align: center;
  border-right: 2px solid #5c3748!important;
  border-bottom: 2px solid #5c3748!important;
}
td {
padding: 10px;
  border-right: 2px solid #5c3748!important;
  text-align: center;

}
table {
  border-collapse: collapse;
}

table tr {
  border-bottom: 2px solid #5c3748!important;
}

/* table tr:last-child {
  border: 0;
} */


table td:first-child {
  /* border: 0; */
  border-left: 3px solid #5c3748!important;
}

table th:last-child {
  border-right: 0;
}

.disabled {
  cursor: default !important;
}


.updateBtn {
  max-width: 300px;
  padding: 8px 16px;
  background: #fff;
  border: 0;
  margin: 20px auto;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 14px;
  border-radius: 6px;
}

.updateBtn:hover {
  cursor: pointer;
}

.updateMsg {
  text-align: center;
  color: #32be3f;
}

@media screen and (max-width: 1919px) {
  .menuDetail {
    width: 1230px;
  }


}

@media screen and (max-width: 1700px) {
  .menuDetail {
    width: 1130px;
  }

}

@media screen and (max-width: 1500px) {
  .menuDetail {
    width: 930px;
  }
  .newDashSub{
    font-size:20px;
  }
  .newDashCamptxt{
    font-size:18px;
  }
  .newDashFormTitle{
    font-size:35px!important;
  }

}

@media screen and (max-width: 1300px) {
  .menuDetail {
    width: 730px;
  }
}

@media screen and (max-width: 1024px) {
  .menuDetail {
    width: 90%;
  }
  .dashRow {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .dashCol {
    width: auto;
  }
  .dashLabel {
    font-size: 16px;
    align-self: start;
  }
  .dashForm {
    padding-left: 15px;
  }
  .dashColInput {
    margin-top: 10px;
    font-size: 15px;
  }
  .dashInput {
    font-size: 15px;
  }
  .dashTitle {
    /* padding-left: 15px; */
    font-size: 25px;
  }
  .dashFormTitle, .newDashFormTitle {
    font-size: 21px!important;
    margin-bottom: 20px;
  }
  .dashAdd {
    align-self: center;
    margin-right: 0px;
    margin-top: 10px;
  }
  .filePreview {
    align-self: center;
    margin-top: 10px;
  }
  .picturePreview {
    align-self: center;
    margin-top: 10px;
    height: 80px;
  }
  .btn {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
  }
  .dashSub, .newDashSub {
    font-size: 13px;
  }
  .dashCamptxt, .newDashCamptxt {
    font-size: 13px;
  }
  .rowSub, .newRowSub {
    margin-right: 10px;
  }
  .rowCamp, .newRowCamp {
    margin-right: 10px;
  }
  .rowBtn, .newRowBtn {
    margin-right: 10px;
  }
  .btnCenterDash {
    padding-left: 10px;
    padding-right: 10px;

  }
  .btnLeftDash {
    padding-left: 10px;
    padding-right: 10px;

  }
  .btnRightDash {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashInputPack {
    width: 50% !important;
  }

  .selectCampain {
    width: auto;
    font-size: 12px;
  }
  .radioContainer {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .DonInput {
    font-size: 12px;
    margin-left: 0px;
  }
  .DonIcon {
    width: 12px;
    height: 12px;
  }
  .priceRow {
    width: auto;
  }
  .priceTxt {
    font-size: 20px;
  }
  .line {
    height: 25px;
  }
}

.dashForm .donateRadioRow {
  width: 100%;
  margin-left: 0;
  align-items: center;
  margin-top: 0;
}

.dashForm .donateRadioRow.radioContainer {
  padding: 10px 0;
}

.dashForm .donateRadioRow > div {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.dashColInput .react-autosuggest__container{
  position: absolute;
  margin-left:40px;
  margin-right:20px;
}

.billetAdd{
  /* box-shadow: 0px 3px 6px #00000029; */
  border:1px solid #491f32;
  margin:10px;
  padding:10px;
  padding-top:30px;
  padding-bottom:10px;
  border-radius:5px;
}

.newBtnNext2{
  border: 3px solid #e00365;
  border-radius: 10px;
  background-color:#e00365;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight:bold;
  font-weight:500;
  letter-spacing: 0px;
  color: #fff;
  height: 28px;
  line-height: 28px;
  /* margin-top: 60px; */
  width: 100px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  padding: 2px;
  transition: all 0.2s ease-in-out;
}

.newBtnNext3{
  border: 3px solid #e00365;
  border-radius: 10px;
  background-color:#e00365;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight:bold;
  font-weight:500;
  letter-spacing: 0px;
  color: #fff;
  height: 28px;
  line-height: 28px;
  /* margin-top: 60px; */
  width: 100px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  padding: 2px;
  transition: all 0.2s ease-in-out;
  width:125px;
}

.newBtnNext:hover,.newBtnNext2:hover,.newBtnNext3:hover{
border: 3px solid #e00365;
background-color:#fff;
color: #e00365;
}
.newBtnNext:active,.newBtnNext2:active,.newBtnNext3:active{
border: 3px solid #e00365;
background-color:#e00365;
color: #fff;
}
div#titretablemargin ,#textetable{
  color: #532c3e;
}

.viewBtn{
  color:#fff!important;
  background-color:rgb(20, 90, 137);
  padding:10px;
  border-radius: 15px;
  cursor:pointer;
  border:1px solid rgb(20, 90, 137)!important;
  text-decoration: none;
}

.viewBtn:hover{
  color:rgb(20, 90, 137)!important;
  background-color:#fff!important;
}

.designContainer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-top:-85px
}
.designContainer select{
  width: 350px;
  height: 50px;
  padding-right: 40px;
}

.row2{
  display: flex;
  align-items: center;
}