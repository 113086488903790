.container {
  height: 104px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #cacaca;
}

.logo {
  height: 105px;
  margin-left: 50px;
  margin-bottom: auto;
  cursor: pointer;
  /* //content: url(../../assets/website_logo.svg); */
  outline: none;
  position: relative;
  top: -14px;
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.link {
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  color: #471d30;
  font-size: 1.2em;
  font-weight: 500;
  /* font: normal normal normal 24px/29px "Helvetica Neue"; */
  letter-spacing: 0px;
}

.linkGroup {
  /* position: relative;
  left: -50%;
  transform: translateX(120%); */
}

.connection {
  width: 100px;
  height: 50px;
  /* box-shadow: 0px 3px 6px #FFFFFF66; */
  border: 3px solid #fff;
  border-radius: 23px;
  opacity: 1;
  text-decoration: none;
  /* padding-left: 30px;
    padding-right: 30px; */
  padding: 10px 40px;
  font-weight: 500;
  line-height: 25px;
  color: #fff;
  background-color: #e00365;
  font-size: 19px;
  margin-right: 20px;
  letter-spacing: 0px;
  transition: 0.2s all ease-in-out;
}

.connection:hover {
  background-color: #fff;
  color: #e00365;
  border: 3px solid #e00365;
}

.searchBar {
  height: 60px;
  box-shadow: 0px 3px 6px #ffffff66;
  border: 3px solid #e00365;
  border-radius: 23px;
  opacity: 1;
  line-height: 60px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 0px;
  color: #471d30;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.searchIcon {
  width: 24px;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}
.searchInput {
  width: 300px;
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 20px;
  font-size: 1.25em;
  flex: 1;
}

.react-autosuggest__container {
  position: relative;
  margin-left: auto;
  margin-right: 20px;
}

.react-autosuggest__input {
  width: 320px;
  height: 30px;
  padding: 10px 20px 10px 40px;
  font-weight: 300;
  font-size: 19px;
  border: 3px solid #e00365;
  border-radius: 23px;
  background-color: #fff;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 360px;
  border: 3px solid #e00365;
  background-color: #fff;
  /* font-family: Helvetica, sans-serif; */
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 23px;
  border-bottom-right-radius: 23px;
  z-index: 2;
}

.react-autosuggest__section-container {
  padding-bottom: 20px;
}

.react-autosuggest__section-container--first {
  border-bottom: 3px solid #e00365;
  margin-bottom: 20px;
}

.react-autosuggest__section-title {
  padding: 5px 20px 10px 20px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 20px;
}

.react-autosuggest__suggestion:hover {
  color: #507cab;
  transition: all 0.3s;
}

.react-autosuggest__suggestion--highlighted {
  background-color: transparent;
}

.inputContainer {
  position: relative;
}

.icon {
  position: absolute;
  top: 18px;
  left: 15px;
  width: 18px;
  height: 18px;
}

.suggestion:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1920px) {
  .link {
    font-size: 1.25em;
    margin-left: 15px;
    margin-right: 15px;
  }

  .react-autosuggest__input {
    font-size: 19px;
    width: 300px;
  }
  .react-autosuggest__suggestions-container--open {
    width: 360px;
  }

  .connection {
    font-size: 19px;
  }

  .logo {
    /* height: 70px;
      margin-left: 30px; */
  }
}

@media screen and (max-width: 1700px) {
  .link {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .react-autosuggest__input {
    font-size: 16px;
    width: 260px;
  }
  .react-autosuggest__suggestions-container--open {
    width: 320px;
  }

  .connection {
    font-size: 16px;
  }

  .logo {
    height: 70px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 1500px) {
  .link {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .react-autosuggest__input {
    font-size: 16px;
    width: 260px;
  }

  .react-autosuggest__suggestions-container--open {
    width: 320px;
  }

  .connection {
    font-size: 16px;
  }

  .logo {
    height: 70px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 1300px) {
  .link {
    font-size: 13px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .react-autosuggest__input {
    font-size: 14px;
    width: 120px;
    height: 20px;
  }

  .react-autosuggest__suggestions-container--open {
    width: 250px;
    top: 41px;
  }

  .icon {
    top: 14px;
  }

  .connection {
    margin-top: 20px;
    text-align: center;
    /* width: 180px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .logo {
    height: 60px;
    margin-left: 20px;
  }
}
/*@media screen and (max-width: 1100px) {*/
/*  .logo {*/
/*    content: url(../../assets/logo-iphone.svg)*/
/*  }*/
/*}*/

@media screen and (max-width: 1024px) {
  .navBar {
    display: none !important;
  }
  .react-autosuggest__container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .link {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    width: 250px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 30px;
  top: 45px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #e10365;
  border-radius: 999px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  /* background: #373a47; */
  background-color: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.langHeader {
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.langHeader span {
  margin-right: 10px;
  display: inline-block;
}

.langHeader span:hover {
  cursor: pointer;
}

.langHeader img {
  width: 32px;
  height: 32px;
  max-height: 32px;
  height: auto;
}

.langMobile {
  margin: 20px auto 0;
  display: flex !important;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
}

.langMobile span {
  margin-right: 10px;
  display: inline-block;
}

.langMobile img {
  width: 100%;
  max-width: 30px;
  height: auto;
}

.headerLeft {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (min-width: 1500px) {
  .langHeader {
    margin-top: 12px;
  }
}

.searchBarContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: -100px;
  margin-top: -8px;
  z-index: 100;
}

.searchbarHidden {
  margin-right: 20px;
  transition: 0.2s all ease-in-out;
}

.searchToggle {
  color: #e00365;
  cursor: pointer;
  margin-top:-30px;
}
