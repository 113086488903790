.donateRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 1350px;
  margin-left: auto;
  margin-right: auto;
}

.inputSignup2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #471d30;
  border-radius: 10px;
  padding: 16px;
  font-size: 22px;
  outline-style: none !important;
  width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 28px;
  color: #491f32;
  display: flex;
  justify-content: center;
}

.donateDl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.donateTxt {
  text-align: center;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.donateSubTitle {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0px;
  margin-top: 20px;
}

.donateRadioRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.donatePrice {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  font-size: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
  font-weight: 300;
  border: 1px solid #491f32;
  color: #491f32;
  font-weight: 600;
}

.lockIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.donateFirstCol {
  display: flex;
  flex-direction: column;
  padding-right: 30px;
}

.donateSecCol {
  display: flex;
  flex-direction: column;
}

.donateLastCol {
  display: flex;
  flex-direction: column;
}

.donateCardInput {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  margin-left: 0px !important;
  margin-right: 20px;
  justify-content: flex-start;
}

.donateInput {
  border: none;
  padding: 20px;
  padding-right: 0px;
  border-radius: 24px;
  outline-style: none;
  font-size: 17px;
  width: 60px;
  border: 1px solid #491f32;
  color: #491f32;
}

.donateInputDate {
  border: none;
  padding: 30px;
  padding-right: 0px;
  border-radius: 24px;
  outline-style: none;
  font-size: 17px;
  width: 40px;
}

.donateInputDate2 {
  border: none;
  padding: 0px;
  padding-right: 0px;
  border-radius: 24px;
  outline-style: none;
  font-size: 17px;
  width: 40px;
}

.donateSmInput {
  border: none;
  padding: 10px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 24px;
  outline-style: none;
  background-color: white;
  text-align: center;
  font-size: 17px;
  margin-left: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.donatePriceBox {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border: 1px solid #957e88;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 200px;
  color: #491f32;
}

.donateFirstPrice {
  width: 100px;
  outline-style: none;
  border: none;
  border-radius: 24px;
  margin: auto !important;
  font-size: 1.75em;
  align-self: center;
  text-align: center;
  background-color: transparent;
  color: rgb(73, 31, 50);
}

.mainDonContainer {
  display: flex;
  justify-content: center;
  width: 1900px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.returnToHomebtn {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 28px;
  line-height: 28px;
  margin-top: 60px;
  width: 300px;
  margin-left: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1919px) {
  .mainDonContainer {
    width: 1700px;
  }
}

@media screen and (max-width: 1700px) {
  .mainDonContainer {
    width: 1500px;
  }
}

@media screen and (max-width: 1500px) {
  .mainDonContainer {
    width: 1300px;
  }
  .returnToHomebtn {
    font-size: 18px;
  }
}

@media screen and (max-width: 1300px) {
  .mainDonContainer {
    width: 1000px;
  }
  .returnToHomebtn {
    font-size: 17px;
  }
}

@media screen and (max-width: 1024px) {
  .mainDonContainer {
    width: 90%;
  }
  .donateTxt {
    font-size: 12px;
  }
  .react-select-container {
    width: 35%;
  }
  .donateLastStepTitle {
    margin-left: 10% !important;
  }
  .cardElem {
    width: 90% !important;
  }
}

.selectAsso {
  width: 100px !important;
  background: #491f32 !important;
}

.select-currency {
  width: 100px;
  /* margin: 20px auto; */
  font-size: 20px;
  padding: 12px 16px;
  border: 1px solid #491f32;
  border-radius: 10px;
  color: #491f32;
}

.select-Interval {
  width: 100%;
  /* margin: 20px auto; */
  font-size: 20px;
  padding: 12px 16px;
  border: 1px solid #491f32;
  border-radius: 10px;
  color: #491f32;
  margin-top: 20px;
}

.yourAmountText {
  color: #471d30;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.btnNext {
  border: 3px solid #e00365;
  border-radius: 10px;
  background-color: #e00365;
  opacity: 1;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #fff;
  height: 28px;
  line-height: 28px;
  margin-top: 60px;
  width: 218px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}
.btnNext:hover {
  border: 3px solid #e00365;
  background-color: #fff;
  color: #e00365;
}
.btnNext:active {
  border: 3px solid #e00365;
  background-color: #e00365;
  color: #fff;
}

.assoc-donation-block-amount-checkbox-wrapper-new {
  width: 280px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.assoc-donation-block-amount-checkbox-line-new {
  width: 310px;
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px;
}

.yourAmountText-new {
  color: #471d30;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .assoc-donation-block-amount-checkbox-wrapper-new {
    font-size: 12px;
  }
  .assoc-donation-block-amount-checkbox-line-new {
    width: 300px;
    font-size: 12px;
  }
  .yourAmountText-new {
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .yourAmountText-new {
    font-size: 16px;
  }

  .assoc-donation-block-amount-checkbox-line-new {
    width: 100%;
    font-size: 12px;
  }

  .assoc-donation-block-amount-checkbox-wrapper-new {
    width: 100%;
    align-items: inherit;
  }
}
