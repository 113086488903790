.dashbooardContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 20px;
  margin-bottom: 30px;
}

.dashbooardMenu {
  width: 278px;
  height: 980px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000033;
  opacity: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
}

.menuCRM {
  height: 28px;
  font-size: 22px;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 20px;
}

.menuCRMSelected {
  height: 28px;
  padding-left: 20px;
  font-size: 22px;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
}

.menu:hover {
  font-weight: bold;
}

.menuImg {
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 60px;
  object-fit: cover;
}

.imgSub {
  align-self: center;
  font-size: 1.25em;
  margin: 0px;
  margin-bottom: 80px;
  margin-top: 12px;
  padding-left: 40px;
  padding-right: 40px;
}

.menuDetail {
  flex-direction: column;
  font-display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 1430px;
}

.dashTitle {
  /* background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 160%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029; */
  /* border-radius: 25px; */
  font-size: 25px;
  flex: 1 1;
  margin-left: -13px;
  /* margin-top: 180px; */
  color: #e30064;
  padding: 10px;
  /* padding-left: 76px; */
}

.dashForm {
  /* background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 200%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029; */
  border-radius: 25px;
  padding-top: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.dashFormTitle {
  font-size: 35px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 60px;
  color: #532c3e;
}

.dashRow {
  /* display: flex;
  flex-direction: row; */
  margin-bottom: 60px;
}

.dashCol {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-right: 20px;
  text-align: center;
}

.dashColInput {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 50px;
}

.dashLabel {
  margin: 0px;
  font-size: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
  align-self: center;
  padding-bottom: 0px;
  color: #532c3e;
}

.dashInput {
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #e30064;
  border-radius: 13px;
  font-size: 20px;
  width: 50% !important;
  font-family: "Helvetica Neue","Roboto", Helvetica, Arial;
}

.dashInputPack {
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border: 1px solid #471d30;
  border-radius: 5px;
  font-size: 20px;
  width: 25% !important;
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
  display: flex;
  flex-direction: column;
}

.packInput {
  color:#471d30;
  border: none;
  font-size: 16px;
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
  margin-top: auto;
  border-top: solid #471d30 1px;
  padding: 10px;
}

.dashAdd {
  padding: 20px;
  padding-left: 35px;
  padding-right: 35px;
  width: 17px;
  height: 17px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.giftImg {
  width: 87px;
  height: 57px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  object-fit: cover
}
.filePreview {
  width: 60px;
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 24px;
}

.picturePreview {
  height: 200px;
  border-radius: 24px;
  margin-left: 15px;
  margin-right: 15px;
}

.videoPreview {
  width: 300px;
  border-radius: 24px;
  margin-left: 15px;
  margin-right: 15px;
  outline: none;
}

.dashAddNo {
  padding: 20px;
  padding-left: 35px;
  padding-right: 35px;
  width: 17px;
  height: 17px;
  margin-right: 20px;
  cursor: pointer;
}

.dashFile {
  margin: 0px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  font-size: 18px;
  color: #959595;
  align-self: center;
  margin-right: 5px;
}

.row {
  flex-direction: row;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  overflow-x: auto;
}

/* Hide scrollbar for Chrome,
Safari and Opera .row::-webkit-scrollbar {
  display: none;
} */

.rowSub {
  flex-direction: row;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #b5b5b5;
  margin-right: 80px;
}

.rowTable {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.rowCamp {
  flex-direction: row;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-around;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  margin-right: 80px;
}

.col {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.btn {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 28px;
  line-height: 28px;
  margin-top: 60px;
  width: 260px;
  margin-left: 20px;
  cursor: pointer;
}

.delBtn {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 28px;
  line-height: 28px;
  margin-top: 5px;
  width: 100px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.btnGeneral:hover {
  background-color: #507cab;
  color: white;
}

.smbtn {
  border: 3px solid #507cab;
  border-radius: 60px;
  text-align: center;
  font-size: 15px;
  color: #000000;
  opacity: 1;
  text-align: center;
  line-height: 28px;
  margin-top: 10px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
}

.btnRight {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 1.25em;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 28px;
  line-height: 28px;
  width: 260px;
  margin-left: auto;
  cursor: pointer;
}

.dashBtns {
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.dashSub {
  font-size: 24px;
  width: 25%;
  text-align: center;
  color: #532c3e;
}
p.tableCell {
  color: #532c3e;
}
.dashFact {
  font-size: 24px;
  text-align: center;
}

.dashCamptxt {
  font-size: 22px;
  width: 25%;
  text-align: center;
}

.btnLeftDash {
  border: 3px solid #507cab;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;

  font-size: 1.25em;
  padding: 5px;
  margin-right: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.btnRightDash {
  border: 3px solid #507cab;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  font-size: 1.25em;
  padding: 5px;
  margin-left: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.btnRoundDash {
  border: 3px solid #507cab;
  border-radius: 60px;
  font-size: 1.25em;
  padding: 5px;
  margin-left: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}
.btnCenterDash {
  border: 3px solid #507cab;
  font-size: 1.25em;
  padding: 5px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.rowBtn {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  margin-right: 80px;
  margin-bottom: 30px;
}

.smllRow {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  margin-right: 60px;
}

.smallTxt {
  color: #6c6c6c;
  font-size: 16px;
  margin: 0px;
  align-self: center;
}

.dashCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 25px;
  flex-direction: row;
  display: flex;
  width: 270px;
  margin-right: 60px;
}

.dashAvatar {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  width: 103px;
  height: 137px;
  object-fit: cover;
  margin-right: 30px;
}

.dashBlueTxt {
  color: #507cab;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 5px;
}

.dashPrice {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  font-size: 40px;
  margin: 0px;
  padding: 12px;
  padding-left: 35px;
  padding-right: 35px;
}

.radioContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  flex: 1;
  flex-direction: row;
  margin-right: 50px;
}

.bankAcountTxt {
  margin: 0px;
  font-size: 22px;
  font-weight: 600;
}

.priceRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background: #e30064 0% 0% no-repeat padding-box;
  border-radius: 60px;
  padding: 10px;
  width: 667px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.line {
  width: 0px;
  height: 49px;
  border: 1px solid #ffffff;
  margin-left: 30px;
  margin-right: 30px;
}

.priceTxt {
  font-size: 35px;
  color: white;
  margin: 0px;
}

.fact-first {
  width: 350px;
  flex-direction: column;
}

.fact-sec {
  width: 280px;
  flex-direction: column;
  border-left: 2px solid #b5b5b5;
  border-right: 2px solid #B5B5B5;
  align-self: center;
}

.fact-third {
  flex-direction: column;
  flex: 4;
}

.factRow {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
}

.noMar {
  margin: 0px;
  align-self: center;
}

.fact-row {
  padding: 20px;
  margin: 0px;
  border-bottom: 2px solid #B5B5B5;
}

.fact-txt {
  border-bottom: 2px solid #B5B5B5;
  padding: 20px;
}

.DonSearchBar {
  background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff66;
  border-radius: 25px;
  flex-direction: row;
  flex: 1;
  margin-right: 20px;
  padding: 5px;
  padding-left: 15px;
  margin-top: 5px;
}

.DonInput {
  font-size: 2.3em;
  color: black;
  border-color: transparent !important;
  background-color: transparent;
  outline-style: none;
  margin: 0px;
  margin-left: 20px;
  width: 90%;
}

.DonIcon {
  width: 24px;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}

.DonTable {
  background: transparent linear-gradient(0deg, #ffffff 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff66;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: 1200px;
  height: 300px;
  overflow: scroll;
}

.don-txt {
  color: #517cab;
  font-size: 1.25em;
  font-weight: 600;
  border-bottom: 2px solid #B5B5B5;
  padding: 10px;
}

.don-subtxt {
  font-size: 1.25em;
  border-bottom: 2px solid #B5B5B5;
  padding: 10px;
}

.don-first {
  width: 250px !important;
  flex-direction: column;
}

.don-sec {
  width: 250px !important;
  flex-direction: column;
  border-left: 2px solid #B5B5B5;
  align-self: center;
  /* min-width: 100px; */
}

.don-third {
  flex-direction: column;
  border-left: 2px solid #B5B5B5;
  width: 250px !important;
}

.selectCampain {
  width: 450px;
  height: 42px;
  border-radius: 40px;
  outline: none;

}

th {
  padding: 10px;
  color: #507CAB;
  text-align: center;
  border-right: 2px solid #909090;
  border-bottom: 2px solid #909090;
}
td {
padding: 10px;
  border-right: 2px solid #909090;
  text-align: center;

}
table {
  border-collapse: collapse;
}

table tr {
  border-bottom: 2px solid #909090;
}

/* table tr:last-child {
  border: 0;
} */


table td:first-child {
  /* border: 0; */
  border-left: 3px solid #909090;
}

table th:last-child {
  border-right: 0;
}

.disabled {
  cursor: default !important;
}


@media screen and (max-width: 1919px) {
  .menuDetail {
    width: 1230px;
  }


}

@media screen and (max-width: 1700px) {
  .menuDetail {
    width: 1130px;
  }

}

@media screen and (max-width: 1500px) {
  .menuDetail {
    width: 930px;
  }

}

@media screen and (max-width: 1300px) {
  .menuDetail {
    width: 730px;
  }
}

@media screen and (max-width: 1024px) {
  .menuDetail {
    width: 90%;
  }
  .dashRow {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .dashCol {
    width: auto;
  }
  .dashLabel {
    font-size: 16px;
    align-self: start;
  }
  .dashForm {
    padding-left: 15px;
  }
  .dashColInput {
    margin-top: 10px;
    font-size: 15px;
  }
  .dashInput {
    font-size: 15px;
  }
  .dashTitle {
    /* padding-left: 15px; */
    font-size: 25px;
  }
  .dashFormTitle {
    font-size: 21px;
    margin-bottom: 20px;
  }
  .dashAdd {
    align-self: center;
    margin-right: 0px;
    margin-top: 10px;
  }
  .filePreview {
    align-self: center;
    margin-top: 10px;
  }
  .picturePreview {
    align-self: center;
    margin-top: 10px;
    height: 80px;
  }
  .btn {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
  }
  .dashSub {
    font-size: 13px;
  }
  .dashCamptxt {
    font-size: 13px;
  }
  .rowSub {
    margin-right: 10px;
  }
  .rowCamp {
    margin-right: 10px;
  }
  .rowBtn {
    margin-right: 10px;
  }
  .btnCenterDash {
    padding-left: 10px;
    padding-right: 10px;

  }
  .btnLeftDash {
    padding-left: 10px;
    padding-right: 10px;

  }
  .btnRightDash {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashInputPack {
    width: 50% !important;
  }

  .selectCampain {
    width: auto;
    font-size: 12px;
  }
  .radioContainer {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .DonInput {
    font-size: 12px;
    margin-left: 0px;
  }
  .DonIcon {
    width: 12px;
    height: 12px;
  }
  .priceRow {
    width: auto;
  }
  .priceTxt {
    font-size: 20px;
  }
  .line {
    height: 25px;
  }
}