.modalWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(80, 124, 171, 0.25);
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}

.modalBlock {
    padding: 40px 75px 40px 50px;
    position: relative;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 3px 1px rgba(80,124,171,0.25);
}

.modalClose {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 25px;
    right: 25px;
}

.modalClose:hover {
    cursor: pointer;
}

.modalClose:before,
.modalClose:after {
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 3px;
    background: #666;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .4s;
}

.modalClose:hover:before,
.modalClose:hover:after {
    background: #333;
}

.modalClose:before {
    transform: rotate(-45deg);
}

.modalClose:after {
    transform: rotate(45deg);
}

.modalBody > div:last-child {
    margin-top: 25px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    align-items: center;
    -webkit-align-items: center;
}