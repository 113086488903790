.assoc-comp-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 60%;
    /* margin-left: auto;
    margin-right: auto; */
}

.assoc-comp-content {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    width: 100%;
}

.assoc-comp-content-title-campName{
    font-weight: 600;
    color:#301824;
    font-size: 50px;
    text-transform: uppercase;

}

@media screen and (max-width:1369px){
    .assoc-comp-content-title-campName{
        font-size: 50px;
    }
}
@media screen and (max-width:1369px){
    .assoc-comp-content-title-campName{
        font-size: 45px;
    }
}
@media screen and (max-width:1170px){
    .assoc-comp-content-title-campName{
        font-size: 45px;
    }
}
@media screen and (max-width:1112px){
    .assoc-comp-content-title-campName{
        font-size: 45px;
    }
}
@media screen and (max-width:1024px){
    .assoc-comp-content-title-campName{
        font-size: 39px;
    }
}
@media screen and (max-width:660px){
    .assoc-comp-content-title-campName{
        font-size: 39px;
    }
}
@media screen and (max-width:530px){
    .assoc-comp-content-title-campName{
        font-size: 30px;
    }
}
@media screen and (max-width:430px){
    .assoc-comp-content-title-campName{
        font-size: 23px;
    }
}
@media screen and (max-width:390px){
    .assoc-comp-content-title-campName{
        font-size: 15px;
    }
}

.assoc-comp-content-title {
    font-size: 40px;
    text-transform: uppercase;
}

@media screen and (max-width:1297px){
    .assoc-comp-content-title {
        font-size:35px
    }
}
@media screen and (max-width:1191px){
    .assoc-comp-content-title {
        font-size:30px
    }
}
@media screen and (max-width:1087px){
    .assoc-comp-content-title {
        font-size:25px
    }
}

.assoc-comp-content-desc {
    font-size: 14px;
    margin-top: 15px;
}
@media screen and (max-width: 1024px) {
    .assoc-comp-container {
        width: 100%;
    }
    .assoc-comp-content {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 90%;
    }
}

.assocContainer-desc{
    margin-left: 130px;
}

@media screen and (max-width:1024px) {
    .assocContainer-desc{
        margin-left:0px
    }
}