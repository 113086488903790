.searchBlock input {
    display: block;
    box-sizing: border-box;
    width: 450px;
    max-width: 450px;
    border-radius: 999px;
    height: 60px;
    border: 2px solid #0f5787;
    padding: 0 20px;
    line-height: 40px;
    font-size: 1em;
    font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;
    margin-left: 80px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.searchBlock input:focus {
    outline: none;
}

@media(max-width: 475px) {
    .searchBlock {
        width: 100%;
        margin-bottom: 20px;
    }
}
