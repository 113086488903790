.aboutUs {
    width: 100%;
    margin: 0 250px;
}

.homeContainer {
    font: normal normal normal 24px/29px "Helvetica Neue";
}

.aboutUs img {
    max-height: 250px;
    border-radius: 25px;
    border: 3px solid white;
    box-shadow: 0px 3px 3px rgba(0,0,0, .15);
}

.aboutUs img:not(:last-child) {
    margin-right: 15px;
}

.aboutUs .images {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.txts{
    width: 80%;
    margin:40px auto;
}

.txts p{
    font-weight: 500;
}

.charityAbout{
    width: 100%;
    background: rgb(195,6,91);
    background: linear-gradient(90deg, rgba(195,6,91,1) 0%, rgba(72,29,48,1) 100%);
    padding:40px;
    color:#fff;
    padding: 60px 250px;
    text-transform: uppercase;
}

.fond{
    width: 890px;
    height: 150px;
    background: rgb(230, 230, 230);
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 40px;
}

@media screen and (max-width:950px){
    .fond{
        width: 860px;
    }
}
@media screen and (max-width:870px){
    .fond{
        width: 790px;
    }
}
@media screen and (max-width:800px){
    .fond{
        width: 760px;
    }
}
@media screen and (max-width:768px){
    .fond{
        display:none;
    }
    .charityAbout{
        padding: 60px 50px;
        font-size:1em;
    }
}


@media screen and (max-width: 1300px) {
    .aboutUs {
        margin: 0 125px;
    }
}

@media screen and (max-width: 768px) {
    .aboutUs {
        margin: 0 50px;
    }
    .homeContainer {
        margin-top: 10px;
    }
    .aboutUs img {
        max-height: none;
        width: 100%;
    }
    .aboutUs .images {
        display: block;
    }
}

@media screen and (max-width:1024px) {
    .aboutUs .images{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .aboutUs .images img{
        margin: 10px 0px;
    }
}
