

.assoc-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 140px;
}

.reverse {
    flex-direction: row-reverse;
}

.assoc-img {
    width: 30%;
/* box-shadow: 0px 3px 6px #00000029; */
border-radius: 25px;
opacity: 1;
object-fit: cover;
height: 450px;
}

.assoc-nostyle {
    width: 30%;
max-height: 350px;
}
.assoc-content {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    height: 450px;
}

.assoc-title {
    text-align: left;
    font: normal normal 600 40px/49px "Helvetica Neue";
    letter-spacing: 0px;
    color: #471d30;
    text-transform: uppercase;
    opacity: 1;
}

.assoc-desc {
    text-align: justify;
    font: normal normal normal 18px/22px "Helvetica Neue";
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 28px;
    margin-bottom: 28px;
    font-size: 22px;
}

.assoc-btn {
    border: 3px solid #e00365;
    background: #e00365;
    border-radius: 10px;
    opacity: 1;
    text-align: center;
    font-size: 28px;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
    padding: 10px;
    margin-top: auto;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.assoc-btn:hover {
    background-color: #fff;
    color: #e00365;
}



@media screen and (max-width: 1919px) {
    .assocContainer {
      width: 1700px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    .assocContainer {
      width: 1500px;
    }
    .assoc-btn {
        font-size: 29px;
    }
    .assoc-desc {
        font-size: 16px !important;
        line-height: 18px;

    }
  }
  
  @media screen and (max-width: 1500px) {
    .assocContainer {
      width: 1300px;
    }
    .assoc-btn {
        font-size: 24px;
    }
    .assoc-desc {
        font-size: 16px !important;
        line-height: 18px;

    }
  }
  
  @media screen and (max-width: 1300px) {
    .assocContainer {
      width: 1000px;
    }
    .assoc-btn {
        font-size: 18px;
    }
    .assoc-desc {
        font-size: 14px !important;
        line-height: 18px;

    }
  }
  .assoc-img-nostyle {
    /* width: 90%; */
    width: 180px;
  }
  @media screen and (max-width: 1024px) {
      .contactForm {
width: 75% !important;
      }
    .assocContainer {
      width: 100%;
    }
    .assoc-img-mobile {
        height: 70px;
        margin-right: 20px;
    }
    .assoc-title {
        font-size: 22px;
    }
    .assoc-block {
        flex-direction: column-reverse;
    }
    .assoc-img {
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;

    }
    .assoc-content {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: auto;
    }
    .assoc-content2 {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 90% !important;
        height: auto;
      }
      
    .mobile {
        display: block !important;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
  
    .desktop {
        display: none !important;
    }
    .reverse {
        flex-direction: column-reverse !important;  
    }
  }

  .mobile {
      display: none;
  }

  .desktop {
      display: block;
  }