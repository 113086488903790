body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
  font-size: 100%;
  /* -webkit-text-size-adjust: none; */
  transform: 1;
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width: 1300px) {
  html {
    font-size: 60%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex: 1;
  min-height: 100%;
  flex-direction: column;
  /* background: transparent linear-gradient(270deg, #EDF9FF88 0%, #FFFFFF 46%, #EDF9FF88 100%) 0% 0% no-repeat padding-box; */
  background:#fff;
  overflow-x: hidden;
}
.DraftEditor-root {
  font-size: 24px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial;
  margin:0px
}

@media screen and (min-width: 1900px) {
  body{
    width: 1800px;
    margin:auto;
  }
}

select{
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%!important;
  -moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
  /* width: 100px; */
  /* margin: 20px auto; */
  font-size: 20px;
  padding: 12px 16px;
  border: 1px solid #491f32;
  border-radius: 10px;
  color: #491f32;
}
