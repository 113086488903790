.footerContainer{
    background: #fff;
    height: 240px;
opacity: 1;
display: flex;
flex-direction: column;
}

.logo-footer {
    height: 105px;
    position:relative;
    top:30px;
}

.socialIcon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
}

.footerCol {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.footerLink {
    margin-top: 25px;
    font-size: 18px;
    cursor: pointer;

    text-decoration: none;
    color: black;
}

@media screen and (max-width: 1024px) {
    .footerContainer{
        height: auto;
        flex-direction: column;
        align-items: center;
        margin: 20px;
    }

    .footerColContainer {
        flex-direction: column !important;
    }

    .footerLogoContainer {
        margin-left: 0px !important;
    }

    .footerCopyright {
        margin-top: 20px !important;
    }
    .footerContainerRow {
        flex-direction: column !important;
    }
    .logo-footer{
        top:0px;
    }
}
