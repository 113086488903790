.closingPage__head {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin-bottom: 25px;
}

.closingPage__head h2,
.closingPage__head > div {
    color: #507CAB!important;
    font-size: 2em;
    font-weight: bold;
    width: 100%;
    max-width: 50%;
}

.closingPage__head > div {
    text-align: right;
}

.closingPage__totalAmount {
    width: 100%;
    max-width: 650px;
    border: 4px solid #8cc8ff!important;
    background: #fff;
    font-size: 1.75em;
    font-weight: bold;
    color: #507CAB!important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 20px;
    margin: 0 auto 25px;
}

.closingPage__totalAmount span {
    color: #8cc8ff!important;
}

.closingPage__amounts {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin-bottom: 50px;
}

.closingPage__amount {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    border: 4px solid #8cc8ff!important;
    background: #fff;
    font-size: 1.25em;
    font-weight: bold;
    color: #8cc8ff!important;
    padding: 15px;
}

.closingPage__amount span {
    display: inline-block;
    margin-left: 25px;
}

.closingPage__facture h3 {
    color: #507CAB!important;
}

.closingPage__factureRow {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    margin-bottom: 20px;
}

.closingPage__factureCell {
    width: 100%;
}

.closingPage__factureCell:last-child {
    max-width: 150px;
    margin-left: 30px;
}

.closingPage__factureCell label {
    display: block;
    color: #507CAB!important;
    font-weight: bold;
    margin-bottom: 5px;
}

.closingPage__factureCell input,
.closingPage__factureCell span {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 4px solid #8cc8ff!important;
    background: #fff;
    padding: 10px;
    font-size: 1.3em;
    font-weight: bold;
}

.closingPage__factureCell input:active,
.closingPage__factureCell input:focus {
    outline: none;
}

.closingPage__addMore {
    margin: 25px 0;
}

.closingPage__addMore h5 {
    display: block;
    color: #507CAB!important;
    font-weight: bold;
    margin-bottom: 20px;
}

.closingPage__addMore button {
    border: 4px solid #8cc8ff!important;
    background: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    font-size: 2em;
    font-weight: bold;
    color: #8cc8ff!important;
    padding: 10px;
}

.closingPage__addMore button:hover {
    cursor: pointer;
}

.closingPage__save {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    margin: 30px 0;
}

.closingPage__save button {
    padding: 7px;
    background: #fff;
    border: 2px solid #507CAB!important;
    color: #507CAB!important;
    border-radius: 20px;
    font-weight: bold;
    font-size: 1em;
    width: 125px;
}

.closingPage__save button:hover {
    cursor: pointer;
}

.closingPage__transfer h3 {
    color: #507CAB!important;
}

.closingPage__transferAmount {
    background: #fff;
    border: 4px solid #8cc8ff!important;
    color: #8cc8ff!important;
    max-width: 600px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 20px;
    margin-bottom: 50px;
}

.closingPage__transferAmount span {
    font-size: 1.5em;
    font-weight: bold;
}

.closingPage__banks table {
    width: 850px;
}

.closingPage__banks table tr {
    border-color:  #8cc8ff!important;
}

.closingPage__banks table tr th {
    color:#507CAB!important;
    border-right: 0!important;
    border-bottom: 0!important;
}

.closingPage__banks table tr td {
    border-color: #8cc8ff!important;
    background: #fff;
    color: #8cc8ff!important;
    border-right: 0!important;
    font-weight: bold;
}

.closingPage__banks table tr td:first-child {
    border-left: 2px solid #8cc8ff!important;
}

.closingPage__banks table tr td:last-child {
    border-right: 2px solid #8cc8ff!important;
}