.pageHead {
    margin: 20px 0;
}

.tableNavbar {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
}

.tableContainer {
    margin-bottom: 40px;
    width: 100%;
}

.table {
    border-radius: 6px;
}

/*.tableHead {*/
/*    background: rgba(80,124,171,0.1);*/
/*}*/

.tableHead th {
    border-right: 1px solid rgb(20, 90, 137)!important;
    border-bottom: 1px solid rgb(20, 90, 137)!important;
    color: #507CAB!important;
}

.tableHead tr {
    border-bottom: 1px solid rgb(20, 90, 137)!important;
}

.tableBody tr {
    border-bottom: 1px solid rgb(20, 90, 137)!important;
    border-left: 1px solid rgb(20, 90, 137)!important;
    color: rgb(20, 90, 137)!important;
    font-weight: bold !important;
}

.tableBody td {
    border-bottom: 1px solid rgb(20, 90, 137)!important;
    border-right: 1px solid rgb(20, 90, 137)!important;
    color: rgb(20, 90, 137)!important;
    font-weight: bold !important;
    /* border-left: 0; */
}

.tableBody td:last-child {
    border-right: 1px solid rgb(20, 90, 137)!important;
    /* border-right: 0; */
}

.tableBody td:first-child {
    border-left: 1px solid rgb(20, 90, 137)!important;
    /* border-left: 0; */
}

.tableLink a {
    color: #507CAB;
    text-decoration: none;
    cursor:pointer;
    transition: all .4s;
}

.tableLink a:hover {
    color: #3a5878;
    text-decoration: underline;
}

.managerVerificationSuccess {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    color: #1f8838;
}

.managerVerificationSuccess img,
.managerVerificationError img {
    margin: 0 10px;
}

.managerVerificationError {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    color: #9c4543;
}

.managerVerificationErrorMsg {
    position: relative;
}

.managerVerificationErrorMsg span {
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    background-color: #e2e2e2;
    border-radius: 50%;
    margin-left: 10px;
}

.managerVerificationErrorMsg span:hover {
    cursor: pointer;
}

.managerVerificationErrorMsg span:hover + div {
    display: block;
}

.managerVerificationErrorMsgText {
    display: none;
    position: absolute;
    width: 200px;
    padding: 20px;
    color: #9c4543;
    text-align: left;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 3px 1px rgba(80,124,171,0.25);
    z-index: 9;
    top: 40px;
    transform: translateX(-40%);
}

.managerVerificationErrorMsgText::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: rotate(45deg) translateX(-50%);
    border-radius: 2px;
    position: absolute;
    top: -1px;
    left: 50%;
    box-shadow: -4px -3px 3px 1px rgba(80,124,171,0.25);
}


@media(max-width: 475px) {
    .tableNavbar {
        flex-direction: column;
        -webkit-flex-direction: column;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        align-items: flex-start;
        -webkit-align-items: flex-start;
    }
}

.viewBtn{
    color:#fff!important;
    background-color:rgb(20, 90, 137);
    padding:10px;
    border-radius: 15px;
    cursor:pointer;
    border:1px solid rgb(20, 90, 137)!important;
    text-decoration: none;
}

.viewBtn:hover{
    color:rgb(20, 90, 137)!important;
    background-color:#fff!important;
}

.tableNavbarButtons{
    display: flex;
}