.loginContainer {
    display: flex;
    justify-content: center;
    width: 1900px;
    margin-left: auto;
    margin-right: auto;
}
.login-form {
    background: transparent linear-gradient(0deg, #FFFFFF 0%, #DCE1FF 180%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 25px;
opacity: 1;
width: 50%;
padding: 30px;
justify-content: center;
display: flex;
flex-direction: column;
align-items: center;
margin-top: 80px;
margin-bottom: 150px;
}

.login-bigInput {
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 24px;
opacity: 1;
text-align: left;
/* font: normal normal normal 14px/16px "Helvetica Neue"; */
font-size: 21px;
letter-spacing: 0px;
color: black;
opacity: 1;
padding-top: 12px;
padding-bottom: 12px;
border-color: transparent;
width: 95%;
margin-left: auto;
margin-right: auto;
outline: none;
margin-top: 25px;
max-width: 610px;
text-align: center;
}


.login-inputCol {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.login-inputRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    max-width: 610px;
}


.login-sendBtn {
    border: 3px solid #507CAB;
    border-radius: 60px;
    opacity: 1;
    text-align: center;
    font-size: 21px;
    /* font-family: '' */
    /* font: normal normal medium 30px "Helvetica Neue"; */
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    height: 48px;
    line-height: 48px;
    margin-top: 60px;
    font-weight: 500;
    cursor: pointer;
}

.login-signupBtn {
    border: 3px solid #507CAB;
    border-radius: 60px;
    opacity: 1;
    text-align: center;
    font-size: 21px;
    /* font-family: '' */
    /* font: normal normal medium 30px "Helvetica Neue"; */
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    height: 48px;
    line-height: 48px;
    margin-top: 90px;
    font-weight: 500;
    width: 30%;
    margin-bottom: 80px;
    cursor: pointer;
}

.login-signupBtn:hover {
    background-color: #507CAB;
    color: white;
}

.login-sendBtn:hover {
    background-color: #507CAB;
    color: white;
}


.login-expText {
    margin-top: 90px;
    width: 80%;
    text-align: left;
font: normal normal normal 18px/22px "Helvetica Neue";
letter-spacing: 0px;
color: #000000;
opacity: 1;
}



.login-left {
    margin-right: 20px;
    width: 288px;
    }
    
    .login-right {
    margin-left: 20px;
    width: 310px;
    }


@media screen and (max-width: 1919px) {
    .loginContainer {
      width: 1700px;
    }

  
  }
  
  @media screen and (max-width: 1700px) {
    .loginContainer {
      width: 1500px;
    }
 
  }
  
  @media screen and (max-width: 1500px) {
    .loginContainer {
      width: 1300px;
    }
  
  }
  
  @media screen and (max-width: 1300px) {
    .loginContainer {
      width: 1000px;
    }

    .login-sendBtn {
        text-align: center;
        font-size: 18px;
    }

    .login-signupBtn {

        font-size: 18px;

    }

  }

  .rdw-editor-main {
      height: auto;
  }
  
  @media screen and (max-width: 1024px) {
    .loginContainer {
      width: 100%;
    }
    .login-form {
        width: 75%;
    }
    .login-inputRow {
        flex-direction: column;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .login-sendBtn {
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .login-signupBtn {
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.fogotPassword{
    color: #e00365;
    cursor: pointer;
    width: 80%;
    margin: auto;
    margin-top: 30px;
    font-size: 22px;
    font-weight: 500;
}

.notRegistered{
    width: 80%;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    font-size: 22px;
}

.signup{
    color: #e00365;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px;
}

@media screen and (max-width:880px) {
    .notRegistered{
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width:620px) {
    .notRegistered, .fogotPassword{
        font-size:18px;
    }
}
@media screen and (max-width:506px) {
    .notRegistered, .fogotPassword{
        font-size:16px;
    }
}
@media screen and (max-width:450px) {
    .notRegistered, .fogotPassword{
        font-size:11px;
    }
}