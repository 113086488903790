.contactFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.contactForm {
  /* background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 180%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  opacity: 1;
  width: 50%;
  padding: 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px;
  margin-bottom: 150px; */
  background:#fff;
  border:2px solid #491f32;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 50px;
}

.cf-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  color:#471d30
}

.cf-desc {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  color:#471d30
}

.cf-smallInput {
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  padding: 16px;
  border-color: transparent;
  width: 40%;
  outline: none; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #471d30;
  border-radius: 10px;
  padding: 16px;
  font-size: 22px;
  outline-style: none !important;
  width: 40% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 28px;
  color: #491f32;
  margin: 40px;
}

.cf-bigInput {
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 14px/16px "Helvetica Neue";
  font-size: 20px;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  padding: 16px;
  border-color: transparent;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  outline: none; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #471d30;
  border-radius: 10px;
  padding: 16px;
  font-size: 22px;
  outline-style: none !important;
  width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 28px;
  color: #491f32;
  margin: 40px;
}

.cf-left {
  margin-right: auto;
}

.cf-right {
  margin-left: auto;
}

.cf-inputRow {
  width: 100%;
  margin:auto;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.cf-inputCol {
  width: 100%;
  margin:auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.cf-textArea {
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  text-align: left;
  font-size: 20px;
  font: normal normal normal 14px/16px "Helvetica Neue";
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  padding: 16px;
  border-color: transparent;
  width: 100%;
  outline: none;
  resize: none;
  height: 200px;
  margin-top: 10px;
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial; */
  border: none;
  padding: 20px;
  padding-right: 0px;
  border-radius: 24px;
  outline-style: none;
  font-size: 17px;
  width: 60px;
  border: 1px solid #491f32;
  color: #491f32;
  width: 80%;
  margin:40px auto;
  height:200px;
}

.cf-textareaTitle {
  text-align: left;
  /* font: normal normal normal 17px/20px "Helvetica Neue"; */
  font-size: 18px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 40px;
}

.cf-sendBtn {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  /* font-family: '' */
  /* font: normal normal medium 30px "Helvetica Neue"; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 10px;
  margin-top: 60px;
  width: 40%;
}

.cf-sendBtn:hover {
  background-color: #507cab;
  color: white;
  cursor: pointer;
}



@media screen and (max-width: 1919px) {
  .contactContainer {
    width: 1700px;
  }
}

@media screen and (max-width: 1700px) {
  .contactContainer {
    width: 1500px;
  }
  .cf-sendBtn {
    font-size: 35px;
  }

}

@media screen and (max-width: 1500px) {
  .contactContainer {
    width: 1300px;
  }

  .cf-title {
    font-size: 35px;
  }
  .cf-desc {
    font-size: 35px;
  }
  .cf-sendBtn {
    font-size: 30px;
  }
}

@media screen and (max-width: 1300px) {
  .contactContainer {
    width: 1000px;
  }
}

@media only screen and (max-width: 600px) {
  .cf-title {
    font: normal normal normal 20px/29px "Helvetica Neue";
  }

  .cf-desc {
    font: normal normal normal 20px/29px "Helvetica Neue";
  }

  .cf-inputRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .cf-left {
    margin-right: auto;
    margin-left: auto;
  }

  .cf-right {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }

  .contactForm {
    width: 60%;
  }

  .cf-smallInput {
    width: 90%!important;
    margin:20px;
  }
  .cf-bigInput {
    width: 90%!important;
    margin:20px;
  }
  .cf-inputCol {
    width: 100%;
  }

  .cf-textArea {
    /* width: 80%; */
  }

  .cf-textareaTitle {
    text-align: left;
    /* font: normal normal normal 17px/20px "Helvetica Neue"; */
  font-size: 17px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 10px;
  }

  .cf-sendBtn {
    width: 80%;
  }
}

input {
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
}


textarea {
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
}

@media only screen and (max-width: 1024px) {

  .cf-smallInput {
    font-size: 14px;
  }
  .cf-bigInput {
    font-size: 14px;
  }
  .cf-textArea {
    font-size: 14px;
  }
  .cf-sendBtn {
    font-size: 18px;
  }
}


.btnNext{
  border: 3px solid #e00365;
  border-radius: 10px;
  background-color:#e00365;
  opacity: 1;
  text-align: center;
  font-size: 25px;
  font-weight:500;
  letter-spacing: 0px;
  color: #fff;
  height: 28px;
  line-height: 28px;
  margin-top: 60px;
  width: 218px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}
.btnNext:hover{
border: 3px solid #e00365;
background-color:#fff;
color: #e00365;
}
.btnNext:active{
border: 3px solid #e00365;
background-color:#e00365;
color: #fff;
}