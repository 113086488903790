.pageHead {
    margin: 20px 0;
}

.checkout {
    width: 100%;
    max-width: 500px;
    background: #fff;
    border: 3px solid #507CAB!important;
    padding: 25px 50px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin: 0 auto 50px;
    font-size: 1.75em;
    font-weight: bold;
    color: #507CAB!important;
}

.checkout span {
    color: #8cc8ff!important;
}

.makeTransfer {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
}

.makeTransfer label {
    display: block;
    color: #8cc8ff!important;
    font-size: 1.25em;
    font-weight: bold;
    margin-right: 35px;
}

.makeTransfer input {
    display: block;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;
    background: #fff;
    border: 2px solid #507CAB!important;
    color: #000;
    padding: 10px 25px;
    font-size: 1.25em;
    margin-right: 35px;
}

.makeTransfer input:focus,
.makeTransfer input:active {
    outline: none;
}

.makeTransfer button {
    border: 2px solid #507CAB!important;
    color: #507CAB!important;
    background: #fff;
    border-radius: 20px;
    width: 100%;
    max-width: 150px;
    text-align: center;
    font-weight: bold;
    font-size: 1.25em;
    padding: 10px 25px;
}

.makeTransfer button:hover {
    cursor: pointer;
}

.transferError {
    color: #9c4543;
}

.transferSuccess {
    color: #1f8838;
}

.transferErrorClose:before {
    transform: rotate(-45deg);
}

.transferErrorClose:after {
    transform: rotate(45deg);
}

.tableTitle {
    margin-top: 50px;
    margin-bottom: 20px;
    color: #507CAB!important;
    font-size: 1.25em;
    font-weight: bold;
}

.tableNavbar {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
}

.tableContainer {
    margin-bottom: 40px;
}

.table {
    border-radius: 6px;
}

.tableHead th {
    border-right: 1px solid rgba(0,0,0,0.1)!important;
    border-bottom: 1px solid rgba(0,0,0,0.1)!important;
    color: #507CAB!important;
}

.tableHead tr {
    border-bottom: 1px solid rgba(0,0,0,0.1)!important;
}

.tableBody tr {
    border-bottom: 1px solid rgba(0,0,0,0.1)!important;
}

.tableBody td {
    border-right: 1px solid rgba(0,0,0,0.1)!important;
    border-left: 0!important;
}

.tableBody td:last-child {
    border-right: 0!important;
}

.tableBody td:first-child {
    border-left: 0!important;
}

.tableLink a {
    color: #507CAB!important;
    text-decoration: none;
    cursor:pointer;
    transition: all .4s;
}

.tableLink a:hover {
    color: #3a5878!important;
    text-decoration: underline;
}

@media(max-width: 475px) {
    .tableNavbar {
        flex-direction: column;
        -webkit-flex-direction: column;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        align-items: flex-start;
        -webkit-align-items: flex-start;
    }
}