#invoicePage > button {
    display: inline-block;
    margin: 0 0 20px 0;
}

.InvoiceTop {
    display: flex;
    margin-bottom: 30px;
}

.InvoiceLogo {
    width: 50%;
}

.InvoiceAddress {
    width: 50%;
}

.InvoiceTableBlock {
    border: 2px solid #507CAB!important;
}

.InvoiceTableHead {
    padding: 10px 0;
    background: #507CAB;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.InvoiceTableRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #507CAB!important;
}

.InvoiceTableRow:last-child {
    border-bottom: 0!important;
}

.InvoiceTableCell:first-child {
    color: #507CAB;
}

.InvoiceTableCell {
    font-weight: bold;
}

.InvoiceMiddle {
    margin-bottom: 30px;
}

.InvoiceTable {
    border: 2px solid #507CAB!important;
    width: 100%;
}

.InvoiceTable thead tr {
    border-bottom: 0!important;
}

.InvoiceTable thead tr th {
    padding: 10px;
    background: #507CAB;
    color: #fff!important;
    font-size: 18px;
    font-weight: bold;
    border: 0!important;
}

.InvoiceTable thead tr th:first-child {
    text-align:left;
}

.InvoiceTable tbody tr {
    border-bottom: 1px solid #507CAB!important;
}

.InvoiceTable tbody tr:last-child {
    border-bottom: 0!important;
}

.InvoiceTable tbody tr td {
    font-weight: bold;
    padding: 10px;
    border-left: 1px solid #507CAB!important;
    border-right: 0!important;
}

.InvoiceTable tbody tr td:first-child {
    text-align: left;
    color: #507CAB;
    border-left: 0!important;
}

.InvoiceBottom {
    margin-bottom: 50px;
}

.InvoiceBottom table {
    margin-bottom: 20px;
}

.InvoiceDates {
    padding-left: 50px;
    font-size: 14px;
    font-weight: bold;
}

.InvoiceDates span {
    color: #507CAB;
}

.InvoiceFooter {
    border-top: 1px solid #507CAB!important;
    padding: 30px 10px;
    text-align: center;
    font-weight: bold;
    color: #507CAB;
}

.viewBtn{
    color:#fff!important;
    background-color:rgb(20, 90, 137);
    padding:10px;
    border-radius: 15px;
    cursor:pointer;
    border:1px solid rgb(20, 90, 137)!important;
    text-decoration: none;
    display: inline-block;
    margin-bottom:40px;
}

.viewBtn:hover{
    color:rgb(20, 90, 137)!important;
    background-color:#fff!important;
}

.tableNavbarButtons{
    display: flex;
}