.lieuDateContainer {
  width: 100%;
  margin: 60px auto;
  display: flex;
  justify-content: space-around;
}

.lieuDate {
  color: #d12164;
  /* text-transform: uppercase; */
  font-size: 30px;
  font-weight: 600;
}

.afficheBilletterieContainer {
  width: 100%;
}

.billetContainer {
  width: 180px;
  height: 190px;
  border-radius:20px;
  background: #eae9e4;
  padding:20px;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  /* align-items:center; */
  font-weight: 600;
  text-align: center;
  margin:20px;
}

.billetTitle{
    font-size:16px;
    font-weight:bold;
    border-bottom: 1px solid #000;
    text-align: center;
}

.billetPrice{
    color:#da2a6f;
    font-size:30px;
    font-weight:bold;
}
.billetRemains{
    margin-bottom:10px;
}

.allBilletsContainer{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
}

.typeText{
    text-align: center;
    color:#301824;
    font-size:30px;
    font-weight:600;
    margin:40px auto;
}

.btnPurchaseBillet{
    font-size:30px;
    color:#fff;
    border:1px solid #fff;
    background:#d12164;
    padding:15px 60px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    transition:0.1s all ease-in-out;
}

.btnPurchaseBillet:hover{
    color:#d12164;
    border:1px solid #d12164;
    background: #fff;
}

.btnPurchaseBillet:active{
    color:#fff;
    border:1px solid #fff;
    background: #d12164;
}

@media screen and (max-width:1000px){

  .lieuDate{
    font-size:25px;
  }
}
@media screen and (max-width:670px){

  .lieuDate{
    font-size:20px;
  }
}
@media screen and (max-width:500px){

  .lieuDateContainer{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
  }

  .lieuDate{
    font-size:20px;
    margin-bottom:20px;
  }
}