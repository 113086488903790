.pageHead {
    margin: 20px 0;
}

.selectRow {
    display: flex;
    display: -webkit-flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    margin-bottom: 50px;
}

.selectWrapper {
    margin-right: 30px;
    width: 100%;
    max-width: 150px;
}

.selectLabel {
    font-size: 12px;
    color: #000!important;
    font-weight: bold;
    margin-bottom: 5px;
}

.selectBlock {
    position: relative;
}

.select {
    position: relative;
    background: #fff;
    padding: 10px 40px 10px 15px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 0px 3px 1px rgba(80, 124, 171, 0.25);
    min-height: 40px;
}

.select::after {
    content: '';
    display: block;
    width: 0;
    border: 5px solid transparent!important;
    border-bottom: 5px solid #000!important;
    transform: rotate(180deg) translateY(40%);
    position: absolute;
    top: 50%;
    right: 10px;
}

.options {
    position: absolute;
    top: calc(100% + 10px);
    background: #fff;
    width: 200px;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px 1px rgba(80, 124, 171, 0.25);
    z-index: 2;
}

.options ul {
    margin: 0;
    padding: 0;
}

.options ul li {
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    border-top: 1px solid rgba(0,0,0,0.15)!important;
    padding: 10px 20px
}

.options ul li:first-child {
    border-top: 0!important;
}

.options ul li:hover {
    cursor: pointer;
    background: rgba(80, 124, 171, 0.15);
}

.tableNavbar {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
    margin-bottom: 20px;
    max-width: 1000px;
}

.tableName {
    font-size: 20px;
    font-weight: bold;
}

.tableContainer {
    margin-bottom: 40px;
}

.table {
    border-radius: 6px;
}

.tableHead th {
    border-right: 1px solid rgba(0,0,0,0.1)!important;
    border-bottom: 1px solid rgba(0,0,0,0.1)!important;
    color: #507CAB!important;
    width: 20%;
}

.tableHead tr {
    border-bottom: 1px solid rgba(0,0,0,0.1)!important;
}

.tableBody tr {
    border-bottom: 1px solid rgba(0,0,0,0.1)!important;
}

.tableBody td {
    border-right: 1px solid rgba(0,0,0,0.1)!important;
    border-left: 0!important;
}

.tableBody td:last-child {
    border-right: 0!important;
}

.tableBody td:first-child {
    border-left: 0!important;
}

.tableLink a {
    color: #507CAB!important;
    text-decoration: none;
    cursor:pointer;
    transition: all .4s;
}

.tableLink a:hover {
    color: #3a5878!important;
    text-decoration: underline;
}

@media(max-width: 475px) {
    .tableNavbar {
        flex-direction: column;
        -webkit-flex-direction: column;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        align-items: flex-start;
        -webkit-align-items: flex-start;
    }
}

.viewBtn{
    color:#fff!important;
    background-color:rgb(20, 90, 137);
    padding:10px;
    border-radius: 15px;
    cursor:pointer;
    border:1px solid rgb(20, 90, 137)!important;
    text-decoration: none;
    text-align: center;
}

.viewBtn:hover{
    color:rgb(20, 90, 137)!important;
    background-color:#fff!important;
}

.tableNavbarButtons{
    display: flex;
}