.assocContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* width: 1900px;
  max-width: 1900px; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.TopAssocContainer {
  display: flex;
  justify-content: space-between;
}

.containerPrincipal {
  width: 100%;
  background: #301824;
  margin-top: 40px;
}

.topVideoCont {
  width: 100%;
  display: flex;
}

.aboutCampain {
  margin-top: 40px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #48333d;
  margin-bottom: 40px;
}
.aboutCampainFuture {
  margin: auto;
  margin-top: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #48333d;
}

.topCampainContainer {
  margin: auto;
  margin-top: 50px;
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 18px;
}

.completedContainer {
  width: 80%;
  margin: 40px auto;
}

.containerCompleted {
  color: #301824;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
}

.reverse {
  flex-direction: row-reverse;
}

.assoc-nostyle {
  width: 30%;
  max-height: 350px;
}

.assoc-content2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-right: 120px;
}

.handContainer {
  width: 30px;
  height: 30px;
  background-color: #d42265;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.assoc-donation-block-amount {
  border-radius: 999px;
  border: 0.2px solid #9a406b;
  font-size: 14px;
  background-color: #fff;
  letter-spacing: 0px;
  color: #dd3476;
  outline: none;
  font-weight: 500;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  /* min-width: 150px; */
  width: 35%;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  max-width: 200px;
}

.assoc-donation-block-amount-selected {
  border-radius: 999px;
  border: 0.2px solid #9a406b;
  font-size: 16px;
  background-color: #dd3476;
  letter-spacing: 0px;
  color: #fff;
  outline: none;
  font-weight: 500;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  /* min-width: 150px; */
  width: 35%;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.select-donFor {
  width: 240px;
  margin: 20px auto;
  font-size: 20px;
  padding: 12px 16px;
  border: 1px solid #9a406b;
  border-radius: 10px;
  color: #dd3476;
}

/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
.assoc-donation-block {
  background: #f1f0ec;
  border-radius: 37px 0px 0px 50px;
  /* width: 100%; */
  width: 230px;
  display: flex;
  flex-direction: column;
  height: auto;
  /* position: absolute; */
  right: 0px;
  margin: 20px 0px;
}

.topDonBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  background-color: #db2b70;
  padding: 10px;
  border-radius: 999px 0px 0px 999px;
  height:50px
}

.topDonBoxTitle {
  font-size: 26px;
  font-weight: bold;
}
.topDonBoxCerfa {
  font-size: 8px;
}

.assoc-donation-block-title {
  text-align: center;
  /* font: normal normal normal 45px/55px "Helvetica Neue"; */
  font-family: "Helvetica Neue";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  /* margin-bottom: 20px; */
  font-size: 20px;
  font-weight: 600;
}

.assoc-select__control {
  border-radius: 10px ;
  /* font-size: 1.55em; */
  font-size: 1.2em;
  outline: none ;
  height: 100%;
  /* background: rgba(73, 31, 50,0.8) ; */
  width: 100px;
  color: #000 ;
}
.assoc-select__control::placeholder,
.css-1uccc91-singleValue {
  color: #fff !important;
}

.assoc-donation-block-amount-input {
  border: 1px solid #9a406b;
  /* border-bottom:1px solid #9a406b;
  border-left:1px solid #9a406b; */
  font-size: 20px;
  background-color: #fff;
  letter-spacing: 0px;
  color: #dd3476;
  outline: none;
  font-weight: 500;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  /* min-width: 150px; */
  width: 35%;
  margin-left: 90px;
  /* margin-right: 10px; */
  font-size: 14px;
  position: relative;
  height: 22px;
}

/* .css-2b097c-container{
  position: absolute;
  left:226px;
  top: -1px;
}

.css-2b097c-container:last-child{
  left:212px;
} */

/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* DONNATEURS */

.TopDonateurContainer {
  width: 310px;
  background: #b4acaf;
  margin: 20px 0px;
  border-radius: 0px 40px 40px 0px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}

.TopDonateurName {
  color: #301824;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 10px;
}

.TopDonateurTxt {
  color: #636664;
  font-size: 12px;
  margin-left: 15px;
  font-weight: 600;
}

.TopDonateurDon {
  color: #d12164;
  font-size: 22px;
  font-weight: 600;
}

/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////// */

.assoc-donation-block-amountLine {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: center;
  border-radius: 10px;
  margin-top: 20px;
}

input {
  cursor: text !important;
}

.donation-block-msg {
  max-height: 40px;
  overflow: hidden;
}

.donation-block-msg:hover {
  max-height: none;
  overflow: auto;
}

.assoc-donation-block-amount-free {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 42px;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  border: none;
  outline: none;
  font-weight: 500;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  /* min-width: 150px; */
  width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

/* .assoc-donation-block-amount-selected {
  background: #507cab 0% 0% no-repeat padding-box;
  color: white;
} */

.assoc-donation-block-amount-checkbox-line {
  display: flex;
  flex-direction: row;
  /* align-self: flex-start; */
  margin-top: 0px;
  align-items: center;
}

.assoc-donation-block-amount-checkbox {
  background: #f1f0ec 0% 0% no-repeat padding-box;
  border: 1px solid #000;
  width: 22px;
  height: 22px;
  border-radius: 11px;
}

.assoc-donation-block-amount-checkbox-text {
  font-size: 12px;
  /* margin-left: 15px; */
}

.assoc-donation-block-amount-checkbox-wrapper {
  /* width:210px; */
  text-align: center;
  /* margin: auto; */
  justify-content: center;
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: auto; */
  /* margin-bottom: 30px; */
}

.assoc-donation-block-amount-donate,
.assoc-donation-block-amount-donate-button {
  /* text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 3px solid #507cab;
  border-radius: 60px;
  padding-top: 5px;
  width: 80%;
  padding-bottom: 5px;
  font-size: 32px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto; */
  background: #d12164;
  border:1px solid #d12164;
  text-transform: uppercase;
  color: #fff;
  width: 180px;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.assoc-donation-block-amount-donate-button {
  border-radius: 999px;
  font-size: 18px;
  padding: 8px;
  margin: 0px auto;
  margin-top: 20px;
}

.assoc-donation-block-amount-donate:hover {
  background-color: #fff;
  color: #d12164;
  cursor: pointer;
}

.assoc-donation-block-amount-donate-txt {
  font-size: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.assocContainer-donations-ranks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
}

.parrain-desc {
  font-size: 24px;
}

.assocContainer-donations-block {
  display: flex;
  flex-direction: column;
  background: #eae9e4;
  border-radius: 25px;
  opacity: 1;
  height: auto;
  width: 200px !important;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.assocContainer-donations-price {
  font-size: 25px;
  margin-top: 20px;
}

.giftModal {
  width: 350px;
  border: rgb(80, 124, 171) solid 2px;
  border-radius: 25px;
  padding: 20px;
  margin-top: 30px;
  position: absolute;
  top: -250px;
  display: flex;
  flex-direction: column;
  left: calc(50% - 175px);
  z-index: 100000;
  margin-left: auto;
  margin-right: auto;
  background: transparent linear-gradient(0deg, #ffffff 0%, #e8ebff 200%) 0% 0%
    no-repeat padding-box;
}

.assocContainer-donations-title {
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-align: center;
  padding-bottom: 10px;
  text-decoration: underline;
}

.assocContainer-desc {
  /* width: 90%;
  margin-left: auto;
  margin-right: auto; */
  margin-bottom: 50px;
    margin-top: 40px;
    font-size: 24px;
}

.react-select-container {
  width: 30%;
  height: 49px;
}

.assoc-slider-img {
  height: 230px;
  border-radius: 20px;
  margin: auto;
  width: 100%;
  object-fit: cover;
  outline: none;
}

.sponsor-slider-img {
  height: 230px;
  border-radius: 20px;
  margin: auto;
  max-width: 90%;
  object-fit: cover;
  outline: none;
}
.donor-slider-img {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin: auto;
  object-fit: cover;
  outline: none;
}

.donation-months-container {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 42px;
  border-radius: 40px;
  outline: none;
}

.dontation-header-containers {
  background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 200%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 52px;
  width: 78%;
  /* width: 1486px; */
  /* height: 220px; */
  /* margin-left: 182px; */
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.containerPrincipal {
  background: #301824;
}

.TopAffiche {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CountdownContainer {
  margin: auto;
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  color: #301824;
}
.CountdownTitle {
  text-align: center;
  width: 50%;
  margin-left: 25px;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 600;
}
.Countdown {
  margin-right: 25px;
}
.CountdownRemains {
  font-weight: 900;
  font-size: 40px;
  font-weight: 500;
  margin-right: 25px;
}

.CountdownNumber {
  font-size: 40px;
  font-weight: 900;
  margin-right: 10px;
}

.CountdownDay {
  font-size: 10px;
  margin-right: 10px;
}

.CountdownDayDot {
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1230px) {
  .CountdownContainer {
    font-size: 40px;
  }
  .CountdownRemains {
    font-size: 28px;
    margin-right: 18px;
  }
  .CountdownNumber {
    font-size: 28px;
  }
  .CountdownNumber,
  .CountdownDay {
    margin-right: 0px;
  }
  .CountdownTitle {
    width: 38%;
    font-size: 28px;
  }
}

.slick-next {
  right: 0px !important;
  background: #e0000008 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 100%;
  width: 30px;
}

.slick-next:before {
  color: black;
  font-size: 30px;
}

.slick-prev:before {
  color: black;
  font-size: 30px;
}

.slick-prev {
  left: 0px !important;
  z-index: 25;
  background: #e0000008 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 100%;
  width: 30px;
}

.dontation-header-containers-block1 {
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.dontation-header-containers-block2 {
  width: 60%;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.dontation-header-containers-objectifBlock {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff66;
  border-radius: 59px;
  opacity: 1;
  width: 75%;
  text-align: center;
  /* font: normal normal bold 32px/39px "Helvetica Neue"; */
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 1.7em;
  margin-top: auto;
  margin-bottom: 8px;
}

.dontation-header-containers-amountBlock {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff66;
  border-radius: 59px;
  opacity: 1;
  color: #507cab;
  text-align: center;
  width: 75%;
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 1.7em;
  margin-bottom: auto;
  margin-top: 8px;
}

.dontation-header-containers-progressBar {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 999px;
  opacity: 1;
  /* height: 30px; */
  height: 32px;
  width: 80%;
  border: 1px solid #503c46;
}

.dontation-header-containers-progressBar-sponsor {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 999px;
  opacity: 1;
  /* height: 30px; */
  height: 32px;
  width: 100%;
  border: 2px solid #503c46;
}

.progressBarContainer {
  display: flex;
  justify-content: center;
}

.progressBarAmount {
  font-size: 20px;
  color: #301824;
  font-weight: 600;
}

.dontation-header-containers-pbCont {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dontation-header-containers-progress {
  background: rgb(48, 25, 37);
  background: linear-gradient(
    90deg,
    rgba(48, 25, 37, 1) 0%,
    rgba(209, 33, 100, 1) 100%
  );
  border-radius: 999px;
  opacity: 1;
  height: 32px;
  width: 0%;
  z-index: 100;
}
.dontation-header-containers-progress-bonus {
  /* background: rgb(48, 25, 37); */
  background: rgb(20, 90, 137);
  border-radius: 999px;
  opacity: 1;
  height: 32px;
  width: 0%;
  margin-top:-32px;
}

.donation-header-timeleft {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 80%;
  margin-bottom: auto;
  margin-top: 8px;
}

.dontation-header-containers-timeleft-container {
  background: white;
  box-shadow: 0px 3px 6px #ffffff66;
  border-radius: 150px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
  padding-top: 1em;
  margin-bottom: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  background: transparent linear-gradient(1800deg, #dce1ff44 0%, #ffffff 10%) 0%
    0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #FFFFFF66; */
}

.dontation-header-containers-timeleft-block {
  width: 33%;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
}

.parain_block {
  /* display: flex !important;
  justify-content: center;
  align-items: center; */
  width: 270px !important;
  cursor: pointer;
  border: 1px solid #000 !important;
  border-radius: 20px;
  padding: 20px;
}

.slider-block {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 270px !important;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.campain-container {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  width: 83%;
  margin-left: auto;
  margin-right: auto;
}

/* .ReactModal__Overlay {
  pointer-events: none;
} */

.campain-block1 {
  width: 57%;
  /* margin-top: 110px; */
}

.campain-block2 {
  width: 43%;
}

.donor_block {
  width: 45%;
}

.campain-slider-img {
  height: 200px;
  border-radius: 20px;
}

.campain-block2-container {
  box-shadow: 0px 3px 6px #00000029;
  border: 3px solid #507cab;
  border-radius: 25px;
  width: 90%;
  max-height: 1000px;
  /* min-height: 1000px; */
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.campain-block2-container::-webkit-scrollbar {
  display: none;
}

.donation-block {
  background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 300%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.share-block {
  background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 300%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  align-self: flex-start;
}

.share-block-top {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 16px;
  align-self: flex-start;
  align-items: center;
}

.h-socialIcon-top {
  width: 32px;
  margin: 5px;
}

.parain-block {
  background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 300%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  width: 45%;
}

.parainNbTitle {
  width: 78%;
  margin-left: auto;
  margin-right: auto;
  font-size: 45px;
  margin-top: 50px;
}

.topVideoStyle {
  /* width: 850px; */
  /* width: 43%; */
  /* margin: auto; */
  display: flex;
  justify-content: center;
}

.donation-header-title {
  font-size: 1.3em;
  text-align: center;
}

.headMedia {
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  outline: none;
  /* height: 650px; */
  height: auto;
  object-fit: cover;
  margin:30px;
}
.headMediaVideo {
  border-radius: 10px;
  width: 80%;
  height:70%;
  /* max-width: 500px; */
  outline: none;
  /* height: 650px; */
  /* height: auto; */
  object-fit: cover;
  margin:30px;
}

.image-gift {
  width: 64px;
  height: 64px;
}

@media screen and (max-width: 1919px) {
  .assocContainer {
    /* width: 1700px; */
    width: 100%;
  }
}

@media screen and (max-width: 1700px) {
  .assocContainer {
    /* width: 1500px; */
    width: 100%;
  }
  .parainNbTitle {
    font-size: 40px;
  }
}

@media screen and (max-width: 1500px) {
  .assocContainer {
    /* width: 1300px; */
    width: 100%;
  }
  /* .assoc-donation-block {
    height: 600px;
  } */

  .parrain-desc {
    font-size: 20px;
  }

  .headMedia {
    /* height: 600px; */
  }
  .parainNbTitle {
    font-size: 35px;
  }
  .image-gift {
    width: 54px;
    height: 54px;
  }
}

@media screen and (max-width: 1300px) {
  .topCampainContainer {
    font-size: 18px;
  }
  .parainNbTitle {
    font-size: 28px !important;
  }
  .assocContainer {
    /* width: 1000px; */
    width: 100%;
  }
  .parrain-desc {
    font-size: 14px;
  }

  /* .assoc-donation-block {
    height: 550px;
  } */

  .assoc-donation-block-title {
    font-size: 26px;
  }
  .assoc-donation-block-amount {
    font-size: 14px;
  }

  /* .assoc-donation-block-amount-donate {
    font-size: 24px;
  } */

  .assoc-donation-block-amount-donate-txt {
    font-size: 12px;
  }

  .assoc-donation-block-amount-checkbox-text {
    font-size: 12px;
  }
  .headMedia {
    /* height: 550px; */
  }
  .image-gift {
    width: 44px;
    height: 44px;
  }
  .assocContainer-donations-price {
    font-size: 26px;
    /* margin-top: 20px; */
  }
  .assocContainer-donations-title {
    font-size: 16px;
  }
}

@media screen and (max-width: 1190px) {
  .topVideoStyleLater {
    left: 30%;
  }
}

@media screen and (max-width: 1024px) {
  .parainContainer{
    flex-direction:column;
    align-items:center;
  }
  .logoParrain{
    margin-bottom:30px;
  }

  .topVideoStyleLater {
    left: 20%;
  }
  .parain_block {
    width: 220px !important;
  }
  .assocContainer {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .TopDonateur {
    display: none;
  }
  .assoc-donation-block-amount-checkbox-line {
    margin-left: 10% !important;
  }
  .assocContainer-donations-block {
    width: 200px !important;
    /* height:20em; */
  }
  .endCampCont {
    width: 90% !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .CountdownContainer {
    flex-direction: column !important;
    align-items: center;
  }
  .CountdownTitle {
    width: 80%;
    margin-bottom: 30px;
  }

  .endCampDonate {
    width: 100% !important;
    margin-left: 0 !important;
    justify-content: center;
    align-items: center;
  }

  .endCampDesc {
    font-size: 18px !important;
    width: 93% !important;
    color: #301824;
    font-weight: 600;
  }
  .donAssocCont {
    flex-direction: column !important;
    width: 100% !important;
  }
  .parain-block {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .dontation-header-containers {
    width: 90%;
    flex-direction: column;
  }
  .dontation-header-containers-block1 {
    width: 100%;
  }
  .dontation-header-containers-block2 {
    width: 100%;
  }
  .dontation-header-containers-pbCont {
    justify-content: center;
    margin-top: 20px;
  }
  .donation-header-timeleft {
    margin-left: auto;
  }
  .topVideoStyle {
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
  }
  /* .assoc-donation-block {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px;
  } */
  .assoc-donation-block-title {
    font-size: 25px;
    margin-top: 27px;
    margin-bottom: 10px;
  }

  .assoc-donation-block-amount {
    font-size: 14px;
  }
  .topVideoCont {
    flex-direction: column !important;
  }
  .headMedia {
    margin-top: 50px;
    /* height: 350px; */
  }
  .share-block {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .campain-container {
    flex-direction: column;
  }
  .campain-block1 {
    width: 100%;
  }
  .campain-block2 {
    width: 100%;
  }
  .assoc-donation-block-amount-checkbox-text {
    font-size: 12px;
  }
  .assoc-donation-block-amount-donate {
    font-size: 16px;
  }

  .assocContainer-donations-price {
    font-size: 20px;
    /* margin-top: 20px; */
  }
  .assocContainer-donations-title {
    font-size: 14px;
  }
}

.wrapper {
  width: 42%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@media screen and (max-width: 1080px) {
  .wrapper {
    width: 92%;
  }

  .wrapper > .assoc-comp-container {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wrapper > .assoc-comp-content > .assoc-comp-content {
    margin-left: 0px;
  }
}

/* TEST CHECKED */

.checked {
  background-color: #507cab;
  color: #fff;
  font-weight: bold;
}
.affects-choices {
  padding: 5px;
}
.checked:nth-child(1) {
  border-radius: 20px 20px 0px 0px;
}
.checked:last-child {
  border-radius: 0px 0px 20px 20px;
}

.affects-container {
  width: 80%;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  margin: auto;
  /* padding:10px 0; */
  text-align: center;
  font-size: 20px;
  border-radius: 20px;
}

@media screen and (max-width: 950px) {
  .topVideoStyleLater {
    left: 10%;
  }
}
@media screen and (max-width: 880px) {
  .topVideoStyleLater {
    position: initial;
  }
  .TopAssocContainer {
    flex-direction: column;
  }
  .assoc-donation-block {
    width: 90%;
    border-radius:40px 40px 50px 50px;
    margin: 20px auto;
  }
  .topDonBox {
    border-radius: 999px;
  }
}

@media screen and (max-width: 555px) {
  .CountdownContainer {
    font-size: 30px;
  }
  .CountdownRemains {
    font-size: 18px;
    margin-right: 4px;
  }
  .CountdownNumber {
    font-size: 18px;
  }
  .CountdownNumber,
  .CountdownDay {
    margin-right: 0px;
  }
  .CountdownTitle {
    width: 100%;
    font-size: 20px;
  }
  .CountdownDayDot {
    width: 28px;
  }
}

.objStatContainer {
  width: 100%;
  background: #eeedec;
}

.objContainer {
  width: 80%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
}
.objSubContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 30px;
  color: #301824;
}

.objCollecte {
  color: #d12164;
  font-size: 50px;
  font-weight: 700;
}
.objDonnateur {
  color: #d12164;
  font-size: 40px;
  font-weight: 600;
}
.objTxt {
  font-size: 30px;
  font-weight: 600;
  color: #301824;
}

@media screen and (max-width: 660px) {



  .assoc-donation-block-amount-input {
    width: 105px !important;
    margin-left: 70px !important;
    margin-right: -2px !important;
  }
  .css-2b097c-container {
    width: 100px;
    position: initial;
    right: 0px;
  }
  .assoc-donation-block-amount-donate-txt {
    font-size: 10px;
  }
  .assoc-comp-content-title-campName {
    font-size: 20px;
  }
  .assoc-comp-content-title {
    font-size: 18px;
  }

  .objCollecte {
    font-size: 30px;
  }
  .objDonnateur {
    font-size: 20px;
  }
  .objSubContainer,
  .objTxt {
    font-size: 25px;
  }
}

@media screen and (max-width: 520px) {
  .objCollecte {
    font-size: 20px;
  }
  .objDonnateur {
    font-size: 20px;
  }
  .objSubContainer,
  .objTxt {
    font-size: 25px;
  }
  .objSubContainer {
    font-size: 12px;
  }
}

.donGeneralContainer {
  width: 670px;
  margin: auto;
}

@media screen and (max-width:1024px){
  .donGeneralContainer {
    width: 100%;
    margin: auto;
  }
  .containerCompleted {
    text-align: center;
    margin-bottom:20px;
    color: #301824;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
  }
}

@media screen and (max-width:370px){
  .donGeneralContainer {
    width: 320px;
    margin: auto;
  }
}
.campain-container-donators {
  width: 80%;
  margin: auto;
}

.campain-subcontainer-donators {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.donation-block-donators {
  width: 40%;
  min-width: 300px;
  margin: 20px;
  box-shadow: 0px 10px 30px #00000014;
  border-radius: 20px;
  padding: 25px;
}

.fame-donator {
  color: #d22164;
  font-size: 20px;
  font-weight: bold;
}

.fame-donator {
  font-size: 16px;
}

.fame-amount {
  color: #311925;
  font-size: 18px;
  font-weight: 600;
}

.fame-date {
  margin-left: auto;
  font-size: 12px;
}

@media screen and (max-width: 1500px) {
  .fame-donator {
    font-size: 18px;
  }
  .fame-donator {
    font-size: 14px;
  }
  .fame-amount {
    font-size: 16px;
  }
  .fame-date {
    font-size: 10px;
  }
}
@media screen and (max-width: 1125px) {
  .donation-block-donators {
    width: 100%;
  }
  .fame-donator {
    font-size: 20px;
  }
  .fame-donator {
    font-size: 16px;
  }
  .fame-amount {
    font-size: 18px;
  }
  .fame-date {
    font-size: 12px;
  }
}
@media screen and (max-width: 550px) {
  .fame-donator {
    font-size: 18px;
  }
  .fame-donator {
    font-size: 14px;
  }
  .fame-amount {
    font-size: 16px;
  }
  .fame-date {
    font-size: 10px;
  }
}

@media screen and (max-width: 550px) {
  .campain-container-donators {
    width: 100%;
  }
  .donation-block-donators {
    min-width: 0px;
  }
  .fame-donator {
    font-size: 14px;
  }
  .fame-donator {
    font-size: 10px;
  }
  .fame-amount {
    font-size: 12px;
  }
  .fame-date {
    font-size: 6px;
  }
}

.parainContainer{
  width: 80%;
  display: flex;
  justify-content:center;
  margin:60px auto;
}

.logoParrain{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 25px;
  border:1px solid #301824;
}

.logoParrainBig{
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 25px;
  border:1px solid #301824;
  margin-bottom:30px;
}

.billetterieCampagneContainer{
  width: 90%;
  margin:auto;
  display: flex;
  justify-content:center;
}

.billetCampagnContainer{
  width: 30%;
  min-width:300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  border:2px solid #301824;
  border-radius: 40px;
  margin:20px;
}

.billetImage{
  width: 100%;
  object-fit: cover;
  height: 200px;
  border-radius:38px 38px 0px 0px
}
.billetDate{
  color:#da2a6f;
  font-size:20px;
  font-weight: 600;
  margin-left:20px;
  margin-top:20px;
  /* margin-bottom:20px; */
}
.billetDesc{
  margin-left:15px;
  margin-top:5px;
  margin-bottom: 15px;
}
.billetLink{
  background-color:#301824;
  text-align: center;
  padding:20px;
  color:#fff;
  font-size:16px;
  font-weight: 600;
  border-radius:0px 0px 38px 38px;
  border-bottom: 1px solid #301824;
  cursor: pointer;
}

.check-donor{
  position:relative;
  top:-59px;
}

@media screen and (max-width:1500px){
  .check-donor{
    position:relative;
    top:-54px;
  }
  
}
@media screen and (max-width:1300px){
  .check-donor{
    position:relative;
    top:-50px;
  }
  
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

input[type="radio"]{
  -webkit-appearance:none;
  background:rgba(0,0,0,0);
  min-width: 25px!important;
  min-height: 25px!important;
  width:25px!important;
  height:25px!important;
  border-radius:50%;
  border: 1px solid #db2b70;
}

input[type="radio"]:checked{
  -webkit-appearance:none;
  background:rgba(0,0,0,0);
}

input[type="radio"]:checked:after{
  width:15px;
  height:15px;
  border-radius:15px;
  top:2px;
  left:2px;
  position: relative;
  background:#db2b70;
  content:'';
  display: inline-block;
  visibility: visible;
  border: 2px solid #db2b70;
}
/* input[type="radio"]:after{
  width: 15px; 
  height: 15px; 
  border-radius: 15px; 
  top: -2px; 
  left: -1px; 
  position: relative; 
  background-color: #d1d3d1; 
  content: ''; 
  display: inline-block; 
  visibility: visible; 
  border: 2px solid white;
} */

input[type="select"]{
  -webkit-appearance:none;
}

input[type="select"]:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
}

.TopDon{
  opacity: 0.4;
  z-index: 1000000!important;
  transition:0.2s all 0.2s ease-in-out;
}

.TopDon:hover{
  opacity: 1
}