.homeContainer {
  /* max-width: 1900px;
  width: 1900px; */
  /* margin-top: 90px; */
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items:center;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.plus-block-container{
  background: rgb(226,27,115);
  background: linear-gradient(90deg, rgba(226,27,115,1) 0%, rgba(71,29,48,1) 100%);
}

.th-title {
  text-align: left;
  font: normal normal normal 45px/55px "Helvetica Neue";
  font-size:25px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;

}

.th-title span{
  font-size:36px;
}

.titleValues{
  text-align: center;
  color:#471d30; 
  font-size:36px; 
  font-weight:bold;
}

.carousel-cont {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  /* padding-right: 60px; */
}

.th-desc {
  text-align: justify;
  font: normal normal normal 24px/29px "Helvetica Neue";
  font-size:18px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin-top: 20px;
  font-weight:600;
}

.marche-desc {
  text-align: justify;
  font: normal normal normal 24px/29px "Helvetica Neue";
  font-size:22px;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  margin-top: 20px;
  font-weight:600;
}

.marche-desc span{
  font-size:25px;
}

.h-socialIcon {
  width: 28px;
  height: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.h-socialIcon.share-link {
  background-color: #475993;
  border-radius: 50%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
}

.h-socialIcon.share-link img {
  display: block;
  width: 100%;
  height: auto;
}

.h-socialIcon.share-link p {
  position: absolute;
  background: #fff;
  padding: 10px;
  font-weight: bold;
  color: rgb(80, 124, 171);
  box-shadow: 0px 3px 6px #00000029;
  width: 150px;
  text-align: center;
  border-radius: 5px;
  transform: translate(-45%, 0);
}

.h-arrowTitle {
  margin-top: 120px;
  text-align: center;
  font: normal normal normal 40px/49px "Helvetica Neue";
  letter-spacing: 0px;
  color: #471d30;
  font-weight: 500;
  opacity: 1;
}

.sb-container {
  width: 95%;
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  /* background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 100%) 0%
    0% no-repeat padding-box; */
  background: #f8f8f8;
  flex-direction: column;
  border-radius: 20px;
  /* border: rgb(80, 124, 171) solid 2px; */
}

.slick-slide div {
  outline: none !important;
}

.stats-container {
  height: 150px;
  background: #f3f3f3;
  /* box-shadow: 0px 3px 6px #ffffff66; */
  opacity: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;

}

.stats-title {
  text-align: center;
  /* font: normal normal normal 42px/24px "Helvetica Neue"; */
  font-family:"Helvetica Neue";
  /* font-size: 60px; */
  font-size: 40px;
  font-weight:500;
  letter-spacing: 0px;
  color: #471d30;
  margin-top: 20px;
}

.stats-desc {
  text-align: center;
  font: normal normal normal 28px/24px "Helvetica Neue";
  letter-spacing: 0px;
  color: #000000;
  margin-top: 30px;
  font-weight: 500;
}

.values-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 75px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.values-col {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.values-col2 {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
}

.containerValue{
  width:50%; 
  margin-top: -400px;
}

.values {
  width: 200px;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 58px;
  /* font: normal normal normal 58px "Helvetica Neue"; */
  letter-spacing: 0px;
  color: #000000;
}

.values-title {
  font: normal normal normal 40px "Helvetica Neue";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 25px;
  /* height: 56px; */
  /* width: 80%; */
}

.values-desc {
  font: normal normal normal 25px "Helvetica Neue";
  letter-spacing: 0px;
  font-size: 27px;
  margin-top: 20px;
  color: #000000;
  text-align: justify;
  padding-bottom: 50px;
  font-weight: 400;
  /* width: 80%; */
}

.yrb-container {
  width: 35%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-top: 100px;
  align-items: center;
  height: 600px;
}

.yrb-title {
  text-align: center;
  font: normal normal bold 40px "Helvetica Neue";
  font-size: 20px;
  letter-spacing: 0px;
  color: #471d30;
  opacity: 1;
}

.yrb-image {
  margin-top: 35px;
  width: 137px;
  height: 138px;
}
.yrb-desc {
  text-align: justify;
  font: normal normal normal 24px/29px "Helvetica Neue";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 40px;
}
.yrb-button {
  margin-top: auto;
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 2.3em;
  font-weight: 500;
  /* font-family: '' */
  /* font: normal normal medium 30px "Helvetica Neue"; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

.yrb-button:hover {
  background-color: #507cab;
  color: white;
}

.pb-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  margin-top: 160px;
  margin-bottom: 80px;
}
.pb-title {
  font-size: 34px;
  margin-top: 20px;
  font-weight: 500;
  color:#ffffff;
}
.pb-img {
  height: 65px;
}

.pb-desc {
  font-size: 24px;
  margin-top: 15px;
  text-align: center;
  color:#ffffff;
}

.yrb-slidercorner {
  background: #e0000008 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

/* div::-webkit-scrollbar {
  display: none;
} */

.btnHome{
  /* margin-top: 20px; */
  text-align: center;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e00365;
  color: #fff;
  border: 3px solid #e00365;
  margin: 20px 10px;
  font-size: 22px;
  border-radius:10px;
  font-weight:600;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.btnHome:hover {
  background-color: #fff;
  color: #e00365;
  border: 3px solid #e00365;
}

.slick-track {
  display: flex !important;
  justify-content: center !important;
}

.slick-slide {
  margin-right: 20px;
  margin-left: 20px;
}

.home-top-left-block{
  width: 27%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  margin-left: auto;
  margin-right: auto;
}

.homeVideo {
  border-radius: 10px;
  outline: none;
  object-fit: contain;
  background-color: #704a58;

  width: 70%;
  height: 70%;
}

.home-slider-price {
  font-size: 14px;
  font-weight: 500;
  color:#471d30;
  font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;
}

.home-slider-title {
font-size: 14px;
height: 34px;
overflow: hidden;
text-overflow: ellipsis;
color:#471d30;
font-weight: bold;
margin-left: 10px;
text-transform: uppercase;
margin-bottom: 15px;
}

.home-slider-sub-title{
  font-size: 12px;
height: 34px;
overflow: hidden;
text-overflow: ellipsis;
color:#000;
margin-left: 10px;
text-transform: uppercase;
margin-top:-12px;
}

.home-slider-subtitle {
  font-size: 12px;
  font-weight:600;
  text-transform: uppercase;
  margin-top:-40px;
}



@media screen and (max-width: 1919px) {
  .homeContainer {
    /* width: 1700px; */
    width: 100%;
  }
  .th-title {
    font-size: 35px;
    line-height: 40px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }

  .btnHome {
    font-size: 22px;
  }

}

@media screen and (max-width: 1700px) {
  .homeContainer {
    /* width: 1500px; */
    width:100%;
  }
  .th-title {
    font-size: 30px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }

  .btnHome {
    font-size: 22px;
  }
}

@media screen and (max-width: 1500px) {
  .homeContainer {
    /* width: 1300px; */
    width:100%;
  }
  .th-title {
    font-size: 27px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }

  .btnHome {
    font-size: 22px;
  }

  .values-title {
    font-size: 30px;
  }

  .values-desc {
    font-size: 27px;
  }
  .values {
    width: 150px;
    height: 150px;
  }
  .yrb-desc {
    font-size: 20px;
  }
}

@media screen and (max-width:1350px){
  .th-title {
    font-size: 26px;
    line-height: 30px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }
}

@media screen and (max-width: 1300px) {
  .homeContainer {
    /* width: 1000px; */
    width: 100%;
  }
  .th-title {
    font-size: 24px;
    line-height: 30px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }

  .btnHome {
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .values-title {
    font-size: 30px;
  }

  .values-desc {
    font-size: 27px;
  }
  .values {
    width: 150px;
    height: 150px;
  }
  .yrb-title {
    font-size: 26px;
  }
  .yrb-desc {
    font-size: 18px;
  }

  .yrb-image {
    width: 100px;
    height: 100px;
  }

  .pb-title {
    font-size: 30px;
  }

  .pb-desc {
    font-size: 18px;
  }
  .stats-title {
    font-size: 50px;
  }
  .stats-desc {
    font-size: 20px;
  }
}

@media screen and (max-width:1200px) {
  .th-title {
    font-size: 23px;
    line-height: 30px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }
}
@media screen and (max-width:1150px) {
  .th-title {
    font-size: 22px;
    line-height: 30px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }
}
@media screen and (max-width:1100px) {
  .th-title {
    font-size: 21px;
    line-height: 30px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }
}
@media screen and (max-width:1050px) {
  .th-title {
    font-size: 20px;
    line-height: 30px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }
}



@media screen and (max-width: 992px) {
  .homeContainer {
    width: 100%;
  }
  .th-title {
    font-size: 24px;
    line-height: 42px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }

  .btnHome {
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .values-title {
    font-size: 30px;
  }

  .values-desc {
    font-size: 27px;
  }
  .values {
    width: 200px;
    height: 200px;
  }
  .yrb-title {
    font-size: 26px;
  }
  .yrb-desc {
    font-size: 18px;
  }
  .yrb-image {
    width: 100px;
    height: 100px;
  }

  .pb-title {
    font-size: 30px;
  }

  .pb-desc {
    font-size: 18px;
  }
  .stats-title {
    font-size: 50px;
  }
  .stats-desc {
    font-size: 20px;
  }
  .homeVideo {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .homeContainer {
    width: 100%;
  }
  .th-title {
    font-size: 24px;
    line-height: 42px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
  }

  .btnHome {
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .values-title {
    font-size: 30px;
  }

  .values-desc {
    font-size: 27px;
  }
  .values {
    width: 200px;
    height: 200px;
  }
  .yrb-title {
    font-size: 30px;
  }
  .yrb-desc {
    font-size: 18px;
  }

  .yrb-image {
    width: 100px;
    height: 100px;
  }

  .pb-title {
    font-size: 30px;
  }

  .pb-desc {
    font-size: 18px;
  }
  .stats-title {
    font-size: 34px;
  }
  .stats-desc {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {

  .stats-title{
    margin-bottom:-20px;
  }

  .containerValue{
    margin-top:0px;
    width: 80%;
  }
  .titleValues{
    font-size:30px;
  }
  .homeContainer {
    width: 100%;
  }
  .home-top-left-block{
    width: 90%;
  }

  .h-arrowTitle {
    font-size: 25px;
  }

  .slider-block {
    margin-left: auto;
    margin-right: auto;
  }

  .home-top-block {
    width:100%;
    margin:auto;
    flex-direction: column !important;
    align-items: center;
  }
  .video-cont {
    width: 100% !important;
  }

  .social-cont {
    display: none !important;
  }

  .stats-block {
    width: 100% !important;
    padding: 10px;
  }

  .pb-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .values-col, .values-col2 {
    width: 100%;
    margin-top: 40px;
    /* flex-direction: column;
    align-items: center;
    justify-content:center; */
    padding-bottom: 30px;
  }

  .plus-block-container {
    flex-direction: column !important;
    align-items: center;
  }

  .yrb-cont {
    flex-direction: column !important;
  }

  .yrb-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }

  .stats-container {
    /* background: transparent linear-gradient(180deg, #ffffff 0%, #dce1ff 200%) 0%
      0% no-repeat padding-box; */
    /* box-shadow: 0px 3px 6px #ffffff66; */
    /* border-radius: 0px; */
    opacity: 1;
    height: auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    /* margin-top: 50px; */
    align-items: center;
    /* height: 150px; */
    background: #f3f3f3;
    /* box-shadow: 0px 3px 6px #ffffff66; */
    opacity: 1;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* display: flex;
    flex-direction: row;
    justify-content:space-evenly; */
    padding-bottom:30px;
  }

  .values-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel-cont {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .th-title {
    font-size: 22px;
    line-height: 35px;
    width: 300px;
    margin: auto;
    margin-bottom: 40px;
  }
  .th-desc, .marche-desc {
    font-size: 22px;
    line-height: 35px;
    max-width: 300px;
    margin: auto;
    margin-top: 40px;
    padding-bottom: 30px;
  }

  .btnHome {
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .values-title {
    font-size: 30px;
  }

  .values-desc {
    font-size: 27px;
  }
  .values {
    width: 200px;
    height: 200px;
  }
  .yrb-title {
    font-size: 30px;
  }
  .yrb-desc {
    font-size: 18px;
  }

  .yrb-image {
    width: 100px;
    height: 100px;
  }

  .pb-title {
    font-size: 30px;
  }

  .pb-desc {
    font-size: 18px;
  }
  .stats-title {
    font-size: 34px;
  }
  .stats-desc {
    font-size: 20px;
  }
}

.topContainer{
  background: url("../../assets/New/home.jpeg") no-repeat top/cover;
  width: 100%;
}
.topContainerFilter{
  width: 100%;
  height: auto;
  background:rgba(112, 74, 88,0.85);
  padding:40px 0px;
}

.home-top-left-block-button{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width:1000px){
  .home-top-left-block-button{
    display: flex;
    justify-content: center;
  }
}

.infoCampagneContainer{
  display: flex;
  justify-content: space-between;
}

.statusCampagne{
  font-size:11px;
  margin-left:20px;
}

.tempsCampagne{
  color:#e00465;
  font-size: 13px;
  margin-right:20px;
}

.slick-prev{
  filter:blur(0px)!important;
  background-color:rgba(0,0,0,0)!important;
  margin-left: -30px;
}
.slick-next{
  filter:blur(0px)!important;
  background-color:rgba(0,0,0,0)!important;
  margin-right: -30px;
}

.slick-dots li button:before{
  margin-top: 40px!important;
  content:""!important;
  width:8px!important;
  height:8px!important;
  border: 1px solid #471d30!important;
  border-radius:50%;
}
.slick-dots li.slick-active button:before{
  margin-top: 40px!important;
  content:""!important;
  width:8px!important;
  height:8px!important;
  background:  #471d30!important;
  border-radius:50%;
}

@media screen and (max-width:846px){
  .titleValues{
    font-size:20px
  }
}
@media screen and (max-width:720px){
  .values-col, .values-col2 {
    width: 100%;
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content:center;
  }
}