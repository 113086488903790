.wrapper {
  display: flex;
}


.mainContainer {
  margin: 40px 0;
  padding: 0 30px;
  display: flex;
  display: -webkit-flex;
}

.pageContent {
  width: calc(100% - 240px);
}

.tableWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.drawerWrapper {
  width: 300px;
  position: relative;
}

@media(max-width: 1280px) {
  .mainContainer {
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  .pageContent {
    width: 100%;
  }
}