/* .loginContainer {
    display: flex;
    justify-content: center;
    width: 1900px;
    margin-left: auto;
    margin-right: auto;
} */


.login-inputCol {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.login-sendBtn {
    border: 3px solid #507CAB;
    border-radius: 60px;
    opacity: 1;
    text-align: center;
    font-size: 21px;
    /* font-family: '' */
    /* font: normal normal medium 30px "Helvetica Neue"; */
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    height: 48px;
    line-height: 48px;
    margin-top: 60px;
    font-weight: 500;
    cursor: pointer;
}

.login-sendBtn:hover {
    background-color: #507CAB;
    color: white;
}


.login-expText {
    margin-top: 90px;
    width: 80%;
    text-align: left;
font: normal normal normal 18px/22px "Helvetica Neue";
letter-spacing: 0px;
color: #000000;
opacity: 1;
}



.login-left {
    margin-right: 20px;
    width: 288px;
    }
    
    .login-right {
    margin-left: 20px;
    width: 310px;
    }


  
  @media screen and (max-width: 1300px) {

    .login-sendBtn {
        text-align: center;
        font-size: 18px;
    }

    .login-signupBtn {

        font-size: 18px;

    }

  }

  .rdw-editor-main {
      height: auto;
  }