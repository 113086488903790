.contactContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 1900px;
    margin-left: auto;
    margin-right: auto;
}


@media screen and (max-width: 1919px) {
    .contactContainer {
      width: 1700px;
    }

  
  }
  
  @media screen and (max-width: 1700px) {
    .contactContainer {
      width: 1500px;
    }
 
  }
  
  @media screen and (max-width: 1500px) {
    .contactContainer {
      width: 1300px;
    }
  
  }
  
  @media screen and (max-width: 1300px) {
    .contactContainer {
      width: 1000px;
    }
  }
  @media screen and (max-width: 1024px) {
    .contactContainer {
      width: 100%;
    }
  }