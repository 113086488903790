.activeLink {
    background: #5c8dac;
    border-radius: 6px;
}
.sidebar {
    width: 240px;
    margin-right: 50px;
}

.sidebar > div {
    /* position: fixed; */
}

@media(max-width: 1280px) {
    .sidebar {
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
    }
    .sidebar > div {
        display: flex;
        display: -webkit-flex;
        position: static;
    }

    .sidebar h1 {
        display: none;
    }
}

.links{
    padding:10px 10px;
    cursor: pointer;
}

.links:hover{
    background-color:#5c8dac;
    border-radius: 15px;
}

.linked{
    padding:10px 10px;
    background-color:#5c8dac;
    border-radius: 15px;
}