.downloadButton {
    border: 0;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 3px 1px rgba(80,124,171,0.25);
    margin-left: 10px;
    /* padding: 10px 25px; */
    font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
    font-size: 1em;
    transition: all .4s;
    background: #e30064;
    color: white;
    width: 80px;
    height: 27px;
    font-size: 18px;
    font-weight: 700;
}

.downloadButtonLink {
    border: 0;
    background: transparent;
    padding: 5px;
    font-family: "Helvetica Neue","Roboto",  Helvetica, Arial;;
    font-size: 0.875em;
    text-decoration: underline;
    color: #666;
}

.downloadButton:first-child {
    margin-left: 0;
}

.downloadButton:hover {
    box-shadow: 0px 0px 10px 1px rgba(80,124,171,0.45);
    cursor: pointer;
}

.downloadButtonLink:hover {
    color: #202020;
    cursor: pointer;
}

@media(max-width: 475px) {
    .downloadButton:first-child {
        margin-left: 0;
    }
}