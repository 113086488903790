.signupContainer {
  /* background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 180%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029; */
  background: #fff;
  border: 2px solid #491f32;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 50px;
}

.signUpRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}
.signUpRowDot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stepTxt {
  font-size: 18px;
  margin: 0px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.signUpEnd {
  font-size: 36px;
  margin: 0px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 60px;
  margin-top: 60px;
}

.stepDot {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  background: rgb(195, 6, 91);
  background: linear-gradient(
    90deg,
    rgba(195, 6, 91, 1) 0%,
    rgba(72, 29, 48, 1) 100%
  );
}

.stepDotEmpty {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #bfbfbf;
}

.stepLine {
  width: 20%;
  height: 0px;
  border: 2px solid #bfbfbf;
}

.stepLineFull {
  width: 20%;
  height: 0px;
  border: 2px solid #d80463;
  background: #d80463;
}

.signupTitle {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  color: #471d30;
}

.newSignupTitle {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  color: #471d30;
  font-family: "Helvetica Neue", "Roboto", Helvetica, Arial;
}

.inputTitle {
  font-size: 1.75em;
  font-weight: 300;
  text-align: center;
  margin: 0px;
  margin-top: 30px;
}

.signupUnderline {
  border: 1px solid #000000;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.signupPlus {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  cursor: pointer;
  width: 147px;
  /* height: 107px; */
  padding: 10px;
  min-height: 90px;
  /* padding-top: 20px; */
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
}

.newSignupPlus {
  border-radius: 24px;
  cursor: pointer;
  width: 147px;
  /* height: 107px; */
  padding: 10px;
  min-height: 90px;
  /* padding-top: 20px; */
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 2px solid #562f41;
}
.newSignupPlus2 {
  border-radius: 24px;
  cursor: pointer;
  width: 75px;
  /* height: 107px; */
  padding: 10px;
  min-height: 50px;
  /* padding-top: 20px; */
  justify-content: center;
  align-items: center;
  /* margin-left: none; */
  /* margin-right: none; */
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 2px solid #562f41;
}

.btnTxt {
  color: black;
  font-size: 15px;
  margin: 0px;
}

.newBtn {
  margin: 20px auto;
}

.newBtnTxt {
  font-family: "Helvetica Neue", "Roboto", Helvetica, Arial;
  color: #562f41;
  font-size: 15px;
  margin: 0px;
}

.newBlockSignUp {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.inputSignup {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #471d30;
  border-radius: 10px;
  padding: 16px;
  font-size: 22px;
  outline-style: none !important;
  width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 28px;
  color: #491f32;
}

.phoneInput {
  border: none !important;
  height: auto !important;
  width: 100% !important;
  font-size: 22px !important;
}

.flag-dropdown {
  background-color: white !important;
  border: none !important;
}

.inputSignupHalf {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #471d30;
  border-radius: 10px;
  padding: 16px;
  font-size: 22px;
  outline-style: none !important;
  width: 37% !important;
  margin-top: 28px;
  color: #491f32;
}

.signuUpForm {
  flex-direction: column;
  /* flex: 1; */
  display: flex;
}

.signUpBtn {
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  padding: 5px;
}

.react-select-country {
  width: 37%;
  border-radius: 40px;
  outline: none;
  height: 58px;
  margin-top: 28px;
  margin-right: 50px;
}

.react-select-country-don {
  width: 41%;
  border-radius: 40px;
  outline: none;
  height: 58px;
  margin-top: 28px;
}

.country-select__control {
  border-radius: 10px !important;
  border: 1px solid #471d30;
  font-size: 1.55em;
  outline: none !important;
  height: 58px;
  color: #491f32;
}

input[type="radio" i] {
  width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 5px;
}

@media screen and (max-width: 1024px) {
  .signupContainer {
    width: 80%;
  }
  .signupTitle {
    font-size: 25px;
  }
  .react-select-country {
    width: 90%;
    margin-right: 0px;
  }
  .signupUnderline {
    border: 1px solid #000000;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }
  .inputSignup,
  .inputSignup2 {
    font-size: 14px;
  }
  .inputSignupHalf {
    font-size: 14px;
    padding: 16px;
    border-style: none;
    outline-style: none !important;
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 28px;

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #471d30;
    border-radius: 10px;
    color: #491f32;
  }
  .phoneInput {
    font-size: 14px !important;
  }

  .signUpRow {
    flex-direction: column;
  }

  .react-select-country-don {
    width: 90%;
  }

  .stepTxt {
    font-size: 10px;
  }
  .signUpEnd {
    font-size: 24px;
  }
}
