.donationsContainer {
  display: flex;
  justify-content: center;
  width: 1900px;
  margin-left: auto;
  margin-right: auto;
}
.dn-form {
  background: transparent linear-gradient(0deg, #ffffff 0%, #dce1ff 180%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  opacity: 1;
  width: 52%;
  padding: 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 150px;
}

.dn-title {
  font-family: Montserrat;
  font-size: 33px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-align: center;
}

.dn-bigInput {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  text-align: left;
  font-size: 22px;
  /* font: normal normal normal 14px/16px "Helvetica Neue"; */
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  padding: 16px;
  border-color: transparent;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.dn-inputRow {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.dn-sendBtn {
  border: 3px solid #507cab;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  font-size: 2.3em;
  /* font-family: '' */
  /* font: normal normal medium 30px "Helvetica Neue"; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 10px;
  margin-top: 60px;
  width: 40%;
}

.dn-sendBtn:hover {
  background-color: #507cab;
  color: white;
  cursor: pointer;
}

.dn-expText {
  margin-top: 90px;
  width: 80%;
  text-align: justify;
  font: normal normal normal 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}


@media screen and (max-width: 1919px) {
  .donationsContainer {
    width: 1700px;
  }


}

@media screen and (max-width: 1700px) {
  .donationsContainer {
    width: 1500px;
  }

}

@media screen and (max-width: 1500px) {
  .donationsContainer {
    width: 1300px;
  }

  .dn-title {
    font-size: 35px;
  }

}

@media screen and (max-width: 1300px) {
  .donationsContainer {
    width: 1000px;
  }
  .dn-title {
    font-size: 30px;
  }

  .dn-expText {
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .donationsContainer {
    width: 100%;
  }
  .dn-form {
    width: 75%;
  }
  .dn-title {
    font-size: 20px;
    line-height: 25px;
  }

  .dn-expText {
    font-size: 14px;
  }
  .dn-bigInput {
    font-size: 14px;
  }

.dn-inputRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
}